/* ----------------------------
 *
 * Typography
 *
 * --------------------------- */
 
 /* Titles */

 // h1{
 //    font-weight: 900;
 // }

 // h2{
 //    font-weight: 900;
 // }

/* Helper
-------------------------------*/

/* 
 * bold
 */
.ultra-bold {
    font-weight: 900;
}

/* 
 * bold
 */
.bold {
    font-weight: 700 !important;
}
/* 
 * semi bold
 */
.semibold {
    font-weight: 600;
}

/* 
 * bold
 */
.normal {
    font-weight: 400 !important;
}
/* 
 * Thin
 */
.thin {
    font-weight: 300;
}
/*
 * ellipsis
 */
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Color
-------------------------------*/
/* 
 * white 
 */
.text-white {
    color: $white !important;
}

.text-orange {
    color: $orange !important;
}

.font-dark {
    color: $font-dark !important;
}