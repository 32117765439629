/* ----------------------------*
 * Global *
 * --------------------------- */
html { 
    // position: relative;
    // z-index: 1;
    // min-height: 100%;
    // letter-spacing: 0.1px;
    // font-size: $font-size-global;
}

body {
    font-family: $font-family;
    background: $light-gray;
    color:$font-light;
    overflow-x: hidden;
}

a{
	color:$blue;
	&:hover{
		color:$blue + 20;
	}
}

p, a, ul, li, button {
    font-family: $font-secondary;
    font-weight: 300;
    letter-spacing: 0.3px;
}

.main{
	background: $light-gray;
}

.titles {
    font-size: 18px;
    font-weight: bold;
    color: $dark;
}

@media ( max-width: 998px ) {
    .container {
        width: 100%;
    }
}

h1 {
    font-size: 30px;
}

h2 {
    font-size: 28px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

.databases {
    .nav-tabs .nav-item.open .nav-link, .nav-tabs .nav-item.open .nav-link:focus, .nav-tabs .nav-item.open .nav-link:hover, .nav-tabs .nav-link.active, .nav-tabs .nav-link.active:focus, .nav-tabs .nav-link.active:hover {
        background: transparent;
        border-bottom: 1px solid #f2f3f6;
    }
}