/* ----------------------------
*
* Button & Link
*
* --------------------------- */

/* anchor reset */
// a {
//     color: $accent-base-color;
//     text-decoration: none;

//     &:hover,
//     &:focus,
//     &:active {
//         color: darken($accent-base-color, 5%);
//         outline: 0;
//         //text-decoration: none;
//     }
// }

// [class="btn"]{
//     background-color: transparent;
//     border-color: transparent;
// }

// /* button reset */
// .btn {
//     //font-size: 13px;
//     //padding: 7px 12px;
//     //line-height: 18px;
//     font-family: $font-family-alt;
//     &:hover,
//     &:active,
//     &:focus {
//         outline: none !important;
//     }

//     &:active,
//     &.active {
//         box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.1);
//     }
//     //
//     //> .caret {
//     //    margin-top: -1px;
//     //}
// }

.btn {
    padding: 8px 30px;
    font-family: $font-secondary;
}



.btn-success {
    border-color: $aqua;
    background-color: $aqua;
    color: $white;
    &:hover{
    	background-color:$aqua - 20;
    }
}


.btn.active {
	background-color: $orange!important;
	font-weight: bold;
	color: $white;
	border-bottom: 4px solid darken($orange,20%);
	transition: all .3s;


	&:hover {
		background-color: darken($orange, 15%);
		color: $white;
	}
}

.btn-gray {
	background-color: $button-gray;
	font-weight: bold;
	color: $gray;
	border-bottom: 4px solid darken($button-gray, 20%);
	transition: all .3s;

	&:hover {
		background-color: darken($button-gray, 10%);
		color: $gray;
	}
}

.btn-orange {
	background-color: $orange;
	font-weight: bold;
	color: #FFF;
	transition: all .3s;

	&:hover {
		background-color: darken($orange, 10%);
	}
}

.btn-upcase { text-transform: uppercase; }

.btn-md {
	padding: 5px 12px;
	font-size: 14px;
}

.btn-sm {
	padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.button-special {
	max-width: 250px;
	padding: 15px 18px;
	width: 100%;
	position: absolute;
	top: 0;
	right: 0;

	span {
		display: block;
	}

    @media ( max-width: 549px ) {
        position: relative;
    }
}

.badge {
    background-color: #0275d8;
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem;
    display: inline-block;
    padding: .25em .4em;
    font-size: 100%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}

.btn-table {
    margin-top: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #FFF !important;
}


///CurrentlyViewingSpan

.currently-viewing{
  position: absolute;
  top:0;
  right:0;
  color: #fe9475;
}
