/* ----------------------------
 *
 * Panel
 *
 * --------------------------- */


/* Panel Header contextual
 *   - default
 *   - danger
 *   - inverse

 -------------------------------*/
/* 
 * default 
 */
// .panel-default{
//     border-style: solid;
//     > .panel-heading {
//         color: $dark;
//         background-color: $white;
//         border-color: $border-color;
//     }
// }

.loader-municipal {
	border: 1px solid #eee;
	border-radius: 5px;
	//box-shadow: inset 0px 1px 10px rgba($dark, .2);
	background-color: #FFF;
	display: flex;
	width: 100%;
	height: 400px;
	justify-content: center;
	align-items: center;

	i {
		color: $dark;
		font-size: 50px;
	}
}

.national-grid{
    position: relative;
    min-height: 200px;
}

.hide-grid{
    visibility: 'hidden' !important;
    height: 0 !important;
    overflow: 'hidden' !important;
}

.block-loading{
	//box-shadow: inset 0px 1px 10px rgba($dark, .2);
    background-color: #FFF;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
    z-index: 9999;
	i {
		color: $dark;
        font-size: 50px;
        z-index: 99999;
	}
}