/* ----------------------------
 *
 * Helper Class
 *
 * --------------------------- */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i,800,800i");
* {
  box-sizing: border-box; }

/* Margin */
.ma35 {
  margin: 35px !important; }

.ma30 {
  margin: 30px !important; }

.ma25 {
  margin: 25px !important; }

.ma20 {
  margin: 20px !important; }

.ma15 {
  margin: 15px !important; }

.ma10 {
  margin: 10px !important; }

.ma5 {
  margin: 5px  !important; }

.nm {
  margin: 0    !important; }

.ma-35 {
  margin: -35px !important; }

.ma-30 {
  margin: -30px !important; }

.ma-25 {
  margin: -25px !important; }

.ma-20 {
  margin: -20px !important; }

.ma-15 {
  margin: -15px !important; }

.ma-10 {
  margin: -10px !important; }

.ma-5 {
  margin: -5px  !important; }

.mt100 {
  margin-top: 100px !important; }

.mt40 {
  margin-top: 40px !important; }

.mt35 {
  margin-top: 35px !important; }

.mt30 {
  margin-top: 30px !important; }

.mt25 {
  margin-top: 25px !important; }

.mt20 {
  margin-top: 20px !important; }

.mt15 {
  margin-top: 15px !important; }

.mt17 {
  margin-top: 17px !important; }

.mt10 {
  margin-top: 10px !important; }

.mt6 {
  margin-top: 6px !important; }

.mt5 {
  margin-top: 5px !important; }

.mt4 {
  margin-top: 4px !important; }

.mt3 {
  margin-top: 3px !important; }

.mt2 {
  margin-top: 2px !important; }

.mt1 {
  margin-top: 1px !important; }

.mt0 {
  margin-top: 0   !important; }

.mt-35 {
  margin-top: -35px !important; }

.mt-30 {
  margin-top: -30px !important; }

.mt-25 {
  margin-top: -25px !important; }

.mt-20 {
  margin-top: -20px !important; }

.mt-15 {
  margin-top: -15px !important; }

.mt-10 {
  margin-top: -10px !important; }

.mt-5 {
  margin-top: -5px  !important; }

.mr35 {
  margin-right: 35px !important; }

.mr30 {
  margin-right: 30px !important; }

.mr25 {
  margin-right: 25px !important; }

.mr20 {
  margin-right: 20px !important; }

.mr15 {
  margin-right: 15px !important; }

.mr10 {
  margin-right: 10px !important; }

.mr5 {
  margin-right: 5px  !important; }

.mr0 {
  margin-right: 0    !important; }

.mr-25 {
  margin-right: -25px !important; }

.mr-20 {
  margin-right: -20px !important; }

.mr-15 {
  margin-right: -15px !important; }

.mr-10 {
  margin-right: -10px !important; }

.mr-5 {
  margin-right: -5px  !important; }

.mb50 {
  margin-bottom: 50px !important; }

.mb40 {
  margin-bottom: 40px !important; }

.mb35 {
  margin-bottom: 35px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mb4 {
  margin-bottom: 4px !important; }

.mb3 {
  margin-bottom: 3px !important; }

.mb2 {
  margin-bottom: 2px !important; }

.mb1 {
  margin-bottom: 1px !important; }

.mb0 {
  margin-bottom: 0   !important; }

.mb-35 {
  margin-bottom: -35px !important; }

.mb-30 {
  margin-bottom: -30px !important; }

.mb-25 {
  margin-bottom: -25px !important; }

.mb-20 {
  margin-bottom: -20px !important; }

.mb-15 {
  margin-bottom: -15px !important; }

.mb-10 {
  margin-bottom: -10px !important; }

.mb-5 {
  margin-bottom: -5px  !important; }

.ml35 {
  margin-left: 35px !important; }

.ml30 {
  margin-left: 30px !important; }

.ml25 {
  margin-left: 25px !important; }

.ml20 {
  margin-left: 20px !important; }

.ml15 {
  margin-left: 15px !important; }

.ml10 {
  margin-left: 10px !important; }

.ml5 {
  margin-left: 5px  !important; }

.ml0 {
  margin-left: 0    !important; }

.ml-35 {
  margin-left: -35px !important; }

.ml-30 {
  margin-left: -30px !important; }

.ml-25 {
  margin-left: -25px !important; }

.ml-20 {
  margin-left: -20px !important; }

.ml-15 {
  margin-left: -15px !important; }

.ml-10 {
  margin-left: -10px !important; }

.ml-5 {
  margin-left: -5px  !important; }

/* Padding */
.pa35 {
  padding: 35px !important; }

.pa30 {
  padding: 30px !important; }

.pa25 {
  padding: 25px !important; }

.pa20 {
  padding: 20px !important; }

.pa15 {
  padding: 15px !important; }

.pa10 {
  padding: 10px !important; }

.pa5 {
  padding: 5px  !important; }

.np {
  padding: 0    !important; }

.pt100 {
  padding-top: 100px !important; }

.pt80 {
  padding-top: 80px !important; }

.pt35 {
  padding-top: 35px !important; }

.pt30 {
  padding-top: 30px !important; }

.pt25 {
  padding-top: 25px !important; }

.pt20 {
  padding-top: 20px !important; }

.pt15 {
  padding-top: 15px !important; }

.pt10 {
  padding-top: 10px !important; }

.pt7 {
  padding-top: 7px  !important; }

.pt6 {
  padding-top: 6px  !important; }

.pt5 {
  padding-top: 5px  !important; }

.pt4 {
  padding-top: 4px  !important; }

.pt0 {
  padding-top: 0    !important; }

.pr35 {
  padding-right: 35px !important; }

.pr30 {
  padding-right: 30px !important; }

.pr25 {
  padding-right: 25px !important; }

.pr20 {
  padding-right: 20px !important; }

.pr15 {
  padding-right: 15px !important; }

.pr10 {
  padding-right: 10px !important; }

.pr5 {
  padding-right: 5px  !important; }

.pr0 {
  padding-right: 0    !important; }

.pb35 {
  padding-bottom: 35px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.pb25 {
  padding-bottom: 25px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pb5 {
  padding-bottom: 5px  !important; }

.pb0 {
  padding-bottom: 0    !important; }

.pl35 {
  padding-left: 35px !important; }

.pl30 {
  padding-left: 30px !important; }

.pl25 {
  padding-left: 25px !important; }

.pl20 {
  padding-left: 20px !important; }

.pl15 {
  padding-left: 15px !important; }

.pl10 {
  padding-left: 10px !important; }

.pl5 {
  padding-left: 5px  !important; }

.pl0 {
  padding-left: 0    !important; }

/* Vertical align */
.valign-top {
  vertical-align: top    !important; }

.valign-middle {
  vertical-align: middle !important; }

.valign-bottom {
  vertical-align: bottom !important; }

/* Flex Vertical align Center */
.flex-valign-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column; }

/* Flex Helpers */
.flex, .flex-center, .flex-align-center, .flex-end {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }

.flex-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.flex-align-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.justify-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }

.flex-end {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end; }

.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column; }

/* Font-awesome */
.fa-mr {
  margin-right: 5px; }

/* Misc */
.full {
  width: 100%; }

.pull-left {
  float: left; }

.pull-right {
  float: right; }

.bradius0 {
  border-radius: 0 !important; }

.bra20 {
  border-radius: 20px !important; }

.bdr0, .bdr-a-0 {
  border-width: 0 !important; }

.bdr-t-0 {
  border-top-width: 0 !important; }

.bdr-b-0 {
  border-bottom-width: 0 !important; }

.noshadow {
  box-shadow: none !important; }

.dis-none {
  display: none; }

.overflow-hidden,
.ovf-h {
  overflow: hidden; }

.float-none {
  float: none !important; }

.handle {
  cursor: move !important; }

.pointer {
  cursor: pointer !important; }

.min-200 {
  min-height: 200px; }

.shadow {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1); }

.box-shadow {
  box-shadow: 0px 2px 2px #DEDEDE; }

.border-left {
  border-left: 1px solid #ddd; }

.border-right {
  border-right: 1px solid #ddd; }

.no-border {
  border: 0 !important; }
  .no-border:after {
    display: none; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.bg-white {
  background-color: #FFF; }

.titles {
  font-size: 14px !important;
  font-weight: bold;
  color: #505050; }

.hide, .hidden {
  display: none !important; }

.container-large .container, .container-large .iep-container {
  max-width: 1220px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  position: relative; }
  .container-large .container:after, .container-large .iep-container:after {
    clear: both;
    display: block;
    content: ''; }

.clearfix:before, .clearfix:after {
  content: "";
  display: block;
  clear: both; }

.loading {
  height: 200px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  color: #505050; }

.p-relative {
  position: relative; }

.w-100 {
  width: 100%; }

.divider {
  border: 1px solid #eee;
  margin-bottom: 15px; }

/* ----------------------------*
 * Global *
 * --------------------------- */
body {
  font-family: "Open Sans", sans-serif;
  background: #F2F3F6;
  color: #95A1AA;
  overflow-x: hidden; }

a {
  color: #1E5EAC; }
  a:hover {
    color: #3272c0; }

p, a, ul, li, button {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  letter-spacing: 0.3px; }

.main {
  background: #F2F3F6; }

.titles {
  font-size: 18px;
  font-weight: bold;
  color: #424143; }

@media (max-width: 998px) {
  .container {
    width: 100%; } }

h1 {
  font-size: 30px; }

h2 {
  font-size: 28px; }

h3 {
  font-size: 24px; }

h4 {
  font-size: 20px; }

h5 {
  font-size: 18px; }

h6 {
  font-size: 16px; }

.databases .nav-tabs .nav-item.open .nav-link, .databases .nav-tabs .nav-item.open .nav-link:focus, .databases .nav-tabs .nav-item.open .nav-link:hover, .databases .nav-tabs .nav-link.active, .databases .nav-tabs .nav-link.active:focus, .databases .nav-tabs .nav-link.active:hover {
  background: transparent;
  border-bottom: 1px solid #f2f3f6; }

/* ----------------------------
 * * Template Header *
 * --------------------------- */
header {
  background: white; }
  header .img-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }

.banner {
  padding: 15px 0;
  background: white; }

@media (max-width: 768px) {
  .header-primary img {
    width: 140px; } }

/* ------------------------------ *
 * * * * * * * footer * * * * * * *
 * ------------------------------ */
.footer {
  padding: 30px 0 0 0;
  font-size: 13px;
  color: white; }

.social-footer a {
  color: white;
  margin-left: 10px;
  font-size: 30px; }
  .social-footer a:hover {
    text-decoration: none; }

.copyright {
  margin-top: 30px;
  color: #95A1AA;
  background: white;
  padding: 10px 0; }
  @media (max-width: 768px) {
    .copyright img {
      width: 130px; } }

/* ---------------------------- *
 * Template Section *
 * --------------------------- */
.section-title, .page-title {
  font-size: 20px;
  font-weight: bold;
  color: #576975;
  text-transform: uppercase; }

.page-title {
  font-size: 20px; }

.page-header {
  background-repeat: no-repeat;
  background-position: right;
  min-height: 200px;
  padding-top: 40px;
  padding-right: 50%;
  padding-left: 15px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #eee; }
  .page-header h2, .page-header p {
    position: relative; }
  .page-header .page-header-overlay {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background: -webkit-gradient(linear, left top, right top, from(#2991f6), to(rgba(13, 222, 242, 0.7)));
    background: linear-gradient(to right, #2991f6, rgba(13, 222, 242, 0.7)); }
  @media (max-width: 768px) {
    .page-header {
      height: auto;
      background-position: center;
      background-size: cover;
      padding-right: 15px;
      color: #576975; } }

.image-gastos {
  background-image: url(../images/header-projects.png); }

.page404 {
  margin-bottom: 100px; }
  .page404 i {
    font-size: 50px;
    margin-bottom: 30px;
    color: #DF252D; }
  .page404 h1 {
    color: #576975;
    font-size: 50px;
    font-weight: bold; }
  .page404 p {
    color: #576975;
    font-size: 20px; }

/*******************************
* 		    Article		      *
*******************************/
.page-article {
  color: #576975;
  margin-top: 40px;
  margin-bottom: 40px; }
  .page-article h1 {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 20px; }

.article-detail {
  padding: 30px 0;
  color: #576975; }
  .article-detail img.img-thumbnail {
    width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 2px 2px #DEDEDE;
    display: block;
    margin-bottom: 30px; }
  .article-detail .btn-primary {
    margin-bottom: 15px; }

/* ----------------------------*
 * Media list *
 * --------------------------- */
.media {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
  margin-top: 5px; }
  .media .media-image {
    width: 90px;
    height: auto;
    margin-right: 10px; }
    .media .media-image img {
      width: 100%;
      height: auto; }
  .media .media-body {
    width: auto;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0%;
        -ms-flex: 1 1 0%;
            flex: 1 1 0%; }
    .media .media-body p {
      font-size: 15px;
      line-height: 1.4; }
    .media .media-body a {
      color: #595A5C; }
  @media (max-width: 768px) {
    .media .media-image {
      width: 100%;
      margin-right: 0; } }

/* ----------------------------
 *
 * Background Color
 *
 * --------------------------- */
/* white */
/* ----------------------------
 *
 * Typography
 *
 * --------------------------- */
/* Titles */
/* Helper
-------------------------------*/
/* 
 * bold
 */
.ultra-bold {
  font-weight: 900; }

/* 
 * bold
 */
.bold {
  font-weight: 700 !important; }

/* 
 * semi bold
 */
.semibold {
  font-weight: 600; }

/* 
 * bold
 */
.normal {
  font-weight: 400 !important; }

/* 
 * Thin
 */
.thin {
  font-weight: 300; }

/*
 * ellipsis
 */
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* Color
-------------------------------*/
/* 
 * white 
 */
.text-white {
  color: #FFFFFF !important; }

.text-orange {
  color: #FE9475 !important; }

.font-dark {
  color: #576975 !important; }

/* ----------------------------
 *
 * Panel
 *
 * --------------------------- */
/* Panel Header contextual
 *   - default
 *   - danger
 *   - inverse

 -------------------------------*/
/* 
 * default 
 */
.loader-municipal {
  border: 1px solid #eee;
  border-radius: 5px;
  background-color: #FFF;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 400px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  .loader-municipal i {
    color: #424143;
    font-size: 50px; }

.national-grid {
  position: relative;
  min-height: 200px; }

.hide-grid {
  visibility: 'hidden' !important;
  height: 0 !important;
  overflow: 'hidden' !important; }

.block-loading {
  background-color: #FFF;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 9999; }
  .block-loading i {
    color: #424143;
    font-size: 50px;
    z-index: 99999; }

/* ----------------------------
 *
 * Card
 *
 * --------------------------- */
a:hover {
  text-decoration: none; }

.card-custom {
  border: 0;
  box-shadow: 0px 2px 3px #DEDEDE;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow: hidden;
  top: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  .card-custom.card-custom-budget {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
        -ms-flex-align: end;
            align-items: flex-end; }
  .card-custom .card-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%; }
  .card-custom .card-link {
    width: 100%;
    padding: 20px;
    padding: 1.25rem;
    -webkit-align-self: flex-end;
        -ms-flex-item-align: end;
            align-self: flex-end;
    text-align: center; }
  .card-custom .card-image {
    display: block;
    position: relative; }
    .card-custom .card-image .card-price {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      color: #FFF;
      background: rgba(30, 94, 172, 0.5);
      opacity: 0;
      font-weight: bold;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-transition: all .3s;
      transition: all .3s; }
      .card-custom .card-image .card-price i {
        display: block; }
    .card-custom .card-image:hover .card-price {
      opacity: 1; }
  .card-custom .card-title-revenue {
    font-size: 16px !important;
    text-decoration: none; }
  .card-custom .card-text-revenue {
    color: #576975; }
  .card-custom:hover {
    box-shadow: 0px 15px 15px #CECECE;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    top: -14px; }
  .card-custom .card-title {
    font-size: 16px;
    line-height: 1.5;
    font-weight: bold;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    color: #576975; }
    .card-custom .card-title a {
      color: #576975;
      text-transform: uppercase;
      font-family: "Open Sans", sans-serif;
      font-weight: bold; }
  .card-custom .card-text {
    font-size: 14px; }

.card-actividad {
  width: 33.333333%;
  background-size: cover;
  background-position: cover;
  height: 420px;
  border-right: 1px solid #FFF;
  margin: 0;
  border-bottom: 1px solid #FFF;
  border-top: 1px solid #FFF; }
  @media (max-width: 768px) {
    .card-actividad {
      width: 100%;
      height: auto; }
      .card-actividad .card-img-overlay {
        position: relative; } }
  .card-actividad:last-child {
    border-right: 0; }
  .card-actividad .card-title {
    font-size: 18px;
    line-height: 1.4;
    font-weight: bold;
    -webkit-transition: color 0.3s;
    transition: color 0.3s; }
  .card-actividad .card-text {
    color: white;
    opacity: 0.8;
    font-size: 14px; }
  .card-actividad img {
    vertical-align: bottom; }
  .card-actividad:hover .card-title {
    color: #FE9475;
    -webkit-transition: color 0.3s;
    transition: color 0.3s; }

.card-tips {
  border-radius: 2px;
  background: white;
  border: 0;
  color: #576975;
  font-weight: bold;
  font-size: 14px; }
  .card-tips .card-title {
    color: #FE9475;
    font-size: 15px;
    font-weight: bold; }

.card-top-line-orange {
  border-top: #FE9475 4px solid; }

.card-top-line-aqua {
  border-top: #3BBDAD 4px solid; }

.card-img-overlay {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(60, 114, 206, 0)), color-stop(75%, rgba(20, 20, 20, 0.9)));
  background: linear-gradient(to top, rgba(60, 114, 206, 0), rgba(20, 20, 20, 0.9) 75%); }

.card-graph {
  background: #FFF;
  border-radius: 5px; }
  .card-graph .card-graph__header {
    border-bottom: 1px solid #ddd;
    padding: 15px;
    position: relative; }
    .card-graph .card-graph__header:after {
      content: "";
      display: block;
      position: absolute;
      width: 15px;
      border-bottom: 1px solid #ddd;
      right: -15px;
      bottom: -1px; }
    .card-graph .card-graph__header span {
      font-style: italic;
      float: right;
      font-size: 25px;
      position: absolute;
      right: 0;
      top: 15px; }
    .card-graph .card-graph__header h4 {
      display: inline-block;
      font-weight: bold;
      font-size: 18px;
      position: relative;
      top: 7px;
      color: #424143;
      padding-right: 80px; }
  .card-graph .card-graph__desc {
    padding: 15px;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 300; }
  .card-graph .card-graph__body {
    padding: 15px 0; }
  @media (max-width: 768px) {
    .card-graph .card-graph__header:after {
      display: none; } }
  @media (max-width: 576px) {
    .card-graph .card-graph__header span {
      right: 15px; } }

.card-list {
  box-shadow: 0px 2px 2px #DEDEDE;
  border-radius: 5px;
  margin-bottom: 15px; }
  .card-list .card-content {
    width: 100%;
    padding-right: 115px;
    position: relative; }
    .card-list .card-content > .row > div {
      min-height: 90px; }
  .card-list .card-block {
    padding: 10px 15px;
    position: relative; }
    .card-list .card-block .card-description {
      background-color: #F9F9F9;
      padding: 10px;
      color: #576975;
      border: 1px solid #E2E2E2;
      font-size: 14px;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      top: 33px;
      left: 15px;
      z-index: 0;
      opacity: 0;
      position: absolute;
      -webkit-transition: all .3s;
      transition: all .3s; }
    .card-list .card-block .card-title {
      margin-bottom: 4.8px;
      margin-bottom: .30rem;
      font-size: 16px;
      font-weight: bold;
      color: #576975;
      cursor: pointer; }
      .card-list .card-block .card-title:hover + .card-description {
        opacity: 1;
        z-index: 100; }
    .card-list .card-block .card-text {
      font-size: 14px; }
  .card-list .card-button {
    background-color: #3BBDAD;
    position: absolute;
    height: 100%;
    right: 0;
    width: 100px;
    top: 0;
    border-radius: 0px 5px 5px 0px; }
    .card-list .card-button button {
      cursor: pointer;
      outline: 0;
      color: #FFF;
      background-color: transparent;
      border: 0;
      font-size: 14px;
      line-height: 1.1;
      padding: 15px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center; }
      .card-list .card-button button i {
        display: block;
        position: relative;
        top: 5px; }
  @media (max-width: 768px) {
    .card-list .card-content {
      padding-right: 0px; }
    .card-list .card-button {
      position: relative;
      width: 100%;
      border-radius: 0px 0px 5px 5px;
      text-align: center; }
    .card-list .text-center.border-right {
      border-right: 0;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: stretch;
      -webkit-align-items: stretch;
          -ms-flex-align: stretch;
              align-items: stretch; }
      .card-list .text-center.border-right .card-block {
        padding-right: 0;
        padding-left: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center;
        width: 100%; }
    .card-list .flex-center {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: stretch;
      -webkit-align-items: stretch;
          -ms-flex-align: stretch;
              align-items: stretch; }
      .card-list .flex-center .card-block {
        border-top: 1px solid #ddd;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center; }
        .card-list .flex-center .card-block > div {
          width: 100%; } }
  @media (max-width: 540px) {
    .card-list .border-right {
      border-right: 0; }
    .card-list .text-center.border-right .card-block {
      border-top: 1px solid #ddd; } }

.card-general {
  background-color: #FFF;
  margin-bottom: 20px; }
  .card-general .card-img-top {
    width: 100%;
    height: auto; }
  .card-general .card-title {
    font-size: 17.6px;
    font-size: 1.1rem;
    font-weight: bold;
    color: #576975; }

.card-publications {
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 2px 2px #DEDEDE;
  background-color: #FFF;
  margin-bottom: 15px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  .card-publications > a {
    display: block;
    width: 100%; }
  .card-publications .card-cover {
    background-size: cover;
    background-position: center;
    height: 200px;
    width: 100%; }
  .card-publications .card-block {
    padding: 15px;
    width: 100%; }
    .card-publications .card-block p {
      margin-bottom: 0;
      font-size: 13px;
      font-style: italic; }
  .card-publications .card-title {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 17px;
    color: #576975;
    margin-bottom: 5px; }
  .card-publications .card-options {
    width: 100%;
    margin: 0;
    padding: 10px 15px;
    background-color: #F9F9F9;
    border-top: 1px solid #ddd;
    list-style: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
            justify-content: space-around;
    -webkit-align-self: flex-end;
        -ms-flex-item-align: end;
            align-self: flex-end; }
    .card-publications .card-options a {
      display: block;
      color: #576975;
      cursor: pointer;
      text-align: center; }
      .card-publications .card-options a i {
        display: block; }
      .card-publications .card-options a span {
        font-size: 12px;
        text-transform: uppercase; }

.card-button {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 25px;
  background-color: #EEE;
  border: 1px solid #e1e1e1;
  color: #202020; }
  .card-button.orange {
    background-color: #FE9475;
    border: #fe805c;
    color: #FFF; }

.card-presentations {
  border: 1px solid #EEEEEE;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 2px 2px #DEDEDE;
  background-color: #FFF;
  margin-bottom: 15px; }
  .card-presentations .card-hero {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 200px; }
    .card-presentations .card-hero .button-download {
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 15px;
      background-color: #FE9475;
      border: 1px solid #fe805c;
      padding: 5px 10px;
      color: #FFF;
      border-radius: 25px;
      font-size: 20px;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
      -webkit-transition: all .3s;
      transition: all .3s; }
      .card-presentations .card-hero .button-download:hover {
        background-color: #fe754c;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.2); }
  .card-presentations .card-title {
    margin-bottom: 0; }
    .card-presentations .card-title a {
      color: #202020;
      font-weight: 400;
      margin-bottom: 0; }
  .card-presentations .card-text {
    font-style: italic; }
  .card-presentations .card-button {
    display: block;
    color: #FFF;
    text-align: center;
    background-color: #1E5EAC;
    border: 1px solid #1a5296;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 15px;
    padding: 10px;
    box-shadow: 0px 3px 3px #DEDEDE;
    -webkit-transition: all .3s;
    transition: all .3s; }
    .card-presentations .card-button:hover {
      background-color: #195092;
      box-shadow: 0px 10px 10px #DEDEDE; }

.card-video {
  background-color: #FFF;
  border: 1px solid #EEE;
  box-shadow: 0px 2px 2px #DEDEDE;
  margin-bottom: 15px;
  border-radius: 4px;
  overflow: hidden; }
  .card-video .card-image {
    background-size: 120%;
    height: 150px;
    background-position: center center;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
    .card-video .card-image:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: rgba(0, 0, 0, 0.2); }
    .card-video .card-image i {
      color: #FFF;
      font-size: 60px;
      position: relative;
      z-index: 10; }
  .card-video .card-content {
    padding: 10px; }
    .card-video .card-content h4 {
      font-size: 15px;
      font-weight: 300;
      font-family: Roboto;
      color: #576975;
      margin: 0; }

/* ----------------------------
*
* Icons
*
* --------------------------- */
/* ----------------------------
 *
 * List Group
 *
 * --------------------------- */
.list-group {
  font-size: 14px; }

.list-group-badge .list-group-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.list-group-no-border .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

/* ----------------------------
 *
 * Carousel
 *
 * --------------------------- */
#slider-header {
  top: -60px; }
  #slider-header .carousel-inner {
    height: 520px; }
    #slider-header .carousel-inner > .carousel-item {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0; }
      #slider-header .carousel-inner > .carousel-item img {
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        max-width: none; }
  @media (min-width: 1000px) {
    #slider-header .carousel-caption {
      left: 25%;
      right: 25%;
      padding-bottom: 10px; } }
  #slider-header .carousel-caption h3 {
    color: #3BBDAD; }
  @media (max-width: 1000px) {
    #slider-header .carousel-inner {
      height: 600px; } }
  @media (max-width: 768px) {
    #slider-header .carousel-caption {
      font-size: 14px; }
      #slider-header .carousel-caption h3 {
        font-size: 24px; } }

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(10%, #3c3c3c), color-stop(10%, rgba(60, 114, 206, 0.5)));
  background: linear-gradient(to top, #3c3c3c 10%, rgba(60, 114, 206, 0.5)); }

/* ----------------------------
 *
 * Nav breadcrumb
 *
 * --------------------------- */
.breadcrumb {
  background: white;
  font-size: 14px;
  overflow-y: hidden;
  height: 42px;
  padding: 0;
  margin: 0;
  padding-right: 10px; }
  .breadcrumb .container, .breadcrumb .iep-container {
    overflow-x: auto;
    white-space: nowrap;
    padding-top: 10px;
    padding-bottom: 30px; }
  .breadcrumb .breadcrumb-item {
    font-weight: bold;
    color: #95A1AA;
    float: inherit; }

.breadcrumb-item + .breadcrumb-item::before {
  content: "\f054";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 12px; }

/* ----------------------------
 *
 * Tooltip
 *
 * --------------------------- */
.tooltip-inner {
  background: #576975;
  font-size: 11px; }
  .tooltip-inner:before {
    border-bottom-color: #576975 !important; }

/* ----------------------------
 *
 * Progress
 *
 * --------------------------- */
.progress[value]::-ms-fill {
  background: linear-gradient(to right, #50dfa5, #3d9ca9); }

.progress[value]::-moz-progress-bar {
  background: linear-gradient(to right, #50dfa5, #3d9ca9); }

.progress[value]::-webkit-progress-value {
  background: -webkit-gradient(linear, left top, right top, from(#50dfa5), to(#3d9ca9));
  background: linear-gradient(to right, #50dfa5, #3d9ca9); }

.progress-content {
  position: relative;
  background-color: #F2F3F6;
  border-radius: 50px;
  margin-bottom: 35px;
  width: 100%; }
  .progress-content .progress-percentage {
    width: 100%;
    height: 10px;
    background: -webkit-gradient(linear, left top, right top, from(#50dfa5), to(#3d9ca9));
    background: linear-gradient(to right, #50dfa5, #3d9ca9);
    position: relative;
    border-radius: 50px; }
    .progress-content .progress-percentage .progress-tooltip {
      position: relative;
      top: 20px;
      background-color: #595A5C;
      padding: 5px 0px;
      color: #FFF;
      font-size: 10px;
      border-radius: 5px;
      position: absolute;
      right: -13px;
      width: 40px;
      text-align: center; }
      .progress-content .progress-percentage .progress-tooltip:after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        top: -4px;
        right: 15px;
        -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
                transform: rotate(45deg);
        background-color: #595A5C; }
      .progress-content .progress-percentage .progress-tooltip:before {
        content: "";
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 50px;
        position: absolute;
        top: -18px;
        right: 17px;
        background-color: #595A5C; }
  .progress-content.progress-fat .progress-percentage {
    height: 15px; }
    .progress-content.progress-fat .progress-percentage .progress-tooltip {
      top: 23px; }
  .progress-content .progress-0 {
    width: 1%; }
  .progress-content .progress-1 {
    width: 1%; }
  .progress-content .progress-2 {
    width: 2%; }
  .progress-content .progress-3 {
    width: 3%; }
  .progress-content .progress-4 {
    width: 4%; }
  .progress-content .progress-5 {
    width: 5%; }
  .progress-content .progress-6 {
    width: 6%; }
  .progress-content .progress-7 {
    width: 7%; }
  .progress-content .progress-8 {
    width: 8%; }
  .progress-content .progress-9 {
    width: 9%; }
  .progress-content .progress-10 {
    width: 10%; }
  .progress-content .progress-11 {
    width: 11%; }
  .progress-content .progress-12 {
    width: 12%; }
  .progress-content .progress-13 {
    width: 13%; }
  .progress-content .progress-14 {
    width: 14%; }
  .progress-content .progress-15 {
    width: 15%; }
  .progress-content .progress-16 {
    width: 16%; }
  .progress-content .progress-17 {
    width: 17%; }
  .progress-content .progress-18 {
    width: 18%; }
  .progress-content .progress-19 {
    width: 19%; }
  .progress-content .progress-20 {
    width: 20%; }
  .progress-content .progress-21 {
    width: 21%; }
  .progress-content .progress-22 {
    width: 22%; }
  .progress-content .progress-23 {
    width: 23%; }
  .progress-content .progress-24 {
    width: 24%; }
  .progress-content .progress-25 {
    width: 25%; }
  .progress-content .progress-26 {
    width: 26%; }
  .progress-content .progress-27 {
    width: 27%; }
  .progress-content .progress-28 {
    width: 28%; }
  .progress-content .progress-29 {
    width: 29%; }
  .progress-content .progress-30 {
    width: 30%; }
  .progress-content .progress-31 {
    width: 31%; }
  .progress-content .progress-32 {
    width: 32%; }
  .progress-content .progress-33 {
    width: 33%; }
  .progress-content .progress-34 {
    width: 34%; }
  .progress-content .progress-35 {
    width: 35%; }
  .progress-content .progress-36 {
    width: 36%; }
  .progress-content .progress-37 {
    width: 37%; }
  .progress-content .progress-38 {
    width: 38%; }
  .progress-content .progress-39 {
    width: 39%; }
  .progress-content .progress-40 {
    width: 40%; }
  .progress-content .progress-41 {
    width: 41%; }
  .progress-content .progress-42 {
    width: 42%; }
  .progress-content .progress-43 {
    width: 43%; }
  .progress-content .progress-44 {
    width: 44%; }
  .progress-content .progress-45 {
    width: 45%; }
  .progress-content .progress-46 {
    width: 46%; }
  .progress-content .progress-47 {
    width: 47%; }
  .progress-content .progress-48 {
    width: 48%; }
  .progress-content .progress-49 {
    width: 49%; }
  .progress-content .progress-50 {
    width: 50%; }
  .progress-content .progress-51 {
    width: 51%; }
  .progress-content .progress-52 {
    width: 52%; }
  .progress-content .progress-53 {
    width: 53%; }
  .progress-content .progress-54 {
    width: 54%; }
  .progress-content .progress-55 {
    width: 55%; }
  .progress-content .progress-56 {
    width: 56%; }
  .progress-content .progress-57 {
    width: 57%; }
  .progress-content .progress-58 {
    width: 58%; }
  .progress-content .progress-59 {
    width: 59%; }
  .progress-content .progress-60 {
    width: 60%; }
  .progress-content .progress-61 {
    width: 61%; }
  .progress-content .progress-62 {
    width: 62%; }
  .progress-content .progress-63 {
    width: 63%; }
  .progress-content .progress-64 {
    width: 64%; }
  .progress-content .progress-65 {
    width: 65%; }
  .progress-content .progress-66 {
    width: 66%; }
  .progress-content .progress-67 {
    width: 67%; }
  .progress-content .progress-68 {
    width: 68%; }
  .progress-content .progress-69 {
    width: 69%; }
  .progress-content .progress-70 {
    width: 70%; }
  .progress-content .progress-71 {
    width: 71%; }
  .progress-content .progress-72 {
    width: 72%; }
  .progress-content .progress-73 {
    width: 73%; }
  .progress-content .progress-74 {
    width: 74%; }
  .progress-content .progress-75 {
    width: 75%; }
  .progress-content .progress-76 {
    width: 76%; }
  .progress-content .progress-77 {
    width: 77%; }
  .progress-content .progress-78 {
    width: 78%; }
  .progress-content .progress-79 {
    width: 79%; }
  .progress-content .progress-80 {
    width: 80%; }
  .progress-content .progress-81 {
    width: 81%; }
  .progress-content .progress-82 {
    width: 82%; }
  .progress-content .progress-83 {
    width: 83%; }
  .progress-content .progress-84 {
    width: 84%; }
  .progress-content .progress-85 {
    width: 85%; }
  .progress-content .progress-86 {
    width: 86%; }
  .progress-content .progress-87 {
    width: 87%; }
  .progress-content .progress-88 {
    width: 88%; }
  .progress-content .progress-89 {
    width: 89%; }
  .progress-content .progress-90 {
    width: 90%; }
  .progress-content .progress-91 {
    width: 91%; }
  .progress-content .progress-92 {
    width: 92%; }
  .progress-content .progress-93 {
    width: 93%; }
  .progress-content .progress-94 {
    width: 94%; }
  .progress-content .progress-95 {
    width: 95%; }
  .progress-content .progress-96 {
    width: 96%; }
  .progress-content .progress-97 {
    width: 97%; }
  .progress-content .progress-98 {
    width: 98%; }
  .progress-content .progress-99 {
    width: 99%; }
  .progress-content .progress-100 {
    width: 100%; }

/*
* Circle progress
*/
.circle-progress {
  width: 100%;
  height: 250px;
  position: relative;
  padding: 15px;
  text-align: center; }
  .circle-progress .circle-item {
    max-width: 100%;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 5px solid #DF252D; }

.circle-content {
  margin-bottom: 55px;
  border-bottom: 1px solid #CCC; }

.circular-graph {
  padding-bottom: 100%;
  width: 100%;
  height: 0;
  border-radius: 50%;
  border: 1px solid #ddd;
  background-color: #f5f8fb;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 15px auto;
  position: relative; }
  .circular-graph .circular-graph-data {
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 10;
    margin-top: -25px;
    font-weight: bold;
    color: #505050;
    font-style: italic;
    left: 0; }
  .circular-graph .down-index > .circular-graph-data {
    margin-top: 15px;
    color: #FFF; }
  .circular-graph .circular-graph-percentage {
    position: absolute;
    width: 100%;
    height: 1%;
    background: -webkit-gradient(linear, left top, left bottom, from(#3BBDAD), to(#1E5EAC));
    background: linear-gradient(#3BBDAD, #1E5EAC);
    bottom: 0;
    -webkit-transition: all .6s;
    transition: all .6s; }
    .circular-graph .circular-graph-percentage:before {
      content: '';
      display: block;
      width: 100%;
      background-image: url(../images/circle-graph-bg.png);
      background-size: cover;
      background-position: bottom;
      height: 15px;
      position: relative;
      top: 0;
      left: 0; }
  .circular-graph.yellow-graph .circular-graph-percentage {
    background: -webkit-gradient(linear, left top, left bottom, from(#fff1d4), to(#FFE1A1));
    background: linear-gradient(#fff1d4, #FFE1A1); }
  .circular-graph.red-graph .circular-graph-percentage {
    background: #C46775; }
  .circular-graph.blue-graph .circular-graph-percentage {
    background: #6AA4B4; }
  .circular-graph .circular-height-1 {
    height: 1%; }
  .circular-graph .circular-height-2 {
    height: 2%; }
  .circular-graph .circular-height-3 {
    height: 3%; }
  .circular-graph .circular-height-4 {
    height: 4%; }
  .circular-graph .circular-height-5 {
    height: 5%; }
  .circular-graph .circular-height-6 {
    height: 6%; }
  .circular-graph .circular-height-7 {
    height: 7%; }
  .circular-graph .circular-height-8 {
    height: 8%; }
  .circular-graph .circular-height-9 {
    height: 9%; }
  .circular-graph .circular-height-10 {
    height: 10%; }
  .circular-graph .circular-height-11 {
    height: 11%; }
  .circular-graph .circular-height-12 {
    height: 12%; }
  .circular-graph .circular-height-13 {
    height: 13%; }
  .circular-graph .circular-height-14 {
    height: 14%; }
  .circular-graph .circular-height-15 {
    height: 15%; }
  .circular-graph .circular-height-16 {
    height: 16%; }
  .circular-graph .circular-height-17 {
    height: 17%; }
  .circular-graph .circular-height-18 {
    height: 18%; }
  .circular-graph .circular-height-19 {
    height: 19%; }
  .circular-graph .circular-height-20 {
    height: 20%; }
  .circular-graph .circular-height-21 {
    height: 21%; }
  .circular-graph .circular-height-22 {
    height: 22%; }
  .circular-graph .circular-height-23 {
    height: 23%; }
  .circular-graph .circular-height-24 {
    height: 24%; }
  .circular-graph .circular-height-25 {
    height: 25%; }
  .circular-graph .circular-height-26 {
    height: 26%; }
  .circular-graph .circular-height-27 {
    height: 27%; }
  .circular-graph .circular-height-28 {
    height: 28%; }
  .circular-graph .circular-height-29 {
    height: 29%; }
  .circular-graph .circular-height-30 {
    height: 30%; }
  .circular-graph .circular-height-31 {
    height: 31%; }
  .circular-graph .circular-height-32 {
    height: 32%; }
  .circular-graph .circular-height-33 {
    height: 33%; }
  .circular-graph .circular-height-34 {
    height: 34%; }
  .circular-graph .circular-height-35 {
    height: 35%; }
  .circular-graph .circular-height-36 {
    height: 36%; }
  .circular-graph .circular-height-37 {
    height: 37%; }
  .circular-graph .circular-height-38 {
    height: 38%; }
  .circular-graph .circular-height-39 {
    height: 39%; }
  .circular-graph .circular-height-40 {
    height: 40%; }
  .circular-graph .circular-height-41 {
    height: 41%; }
  .circular-graph .circular-height-42 {
    height: 42%; }
  .circular-graph .circular-height-43 {
    height: 43%; }
  .circular-graph .circular-height-44 {
    height: 44%; }
  .circular-graph .circular-height-45 {
    height: 45%; }
  .circular-graph .circular-height-46 {
    height: 46%; }
  .circular-graph .circular-height-47 {
    height: 47%; }
  .circular-graph .circular-height-48 {
    height: 48%; }
  .circular-graph .circular-height-49 {
    height: 49%; }
  .circular-graph .circular-height-50 {
    height: 50%; }
  .circular-graph .circular-height-51 {
    height: 51%; }
  .circular-graph .circular-height-52 {
    height: 52%; }
  .circular-graph .circular-height-53 {
    height: 53%; }
  .circular-graph .circular-height-54 {
    height: 54%; }
  .circular-graph .circular-height-55 {
    height: 55%; }
  .circular-graph .circular-height-56 {
    height: 56%; }
  .circular-graph .circular-height-57 {
    height: 57%; }
  .circular-graph .circular-height-58 {
    height: 58%; }
  .circular-graph .circular-height-59 {
    height: 59%; }
  .circular-graph .circular-height-60 {
    height: 60%; }
  .circular-graph .circular-height-61 {
    height: 61%; }
  .circular-graph .circular-height-62 {
    height: 62%; }
  .circular-graph .circular-height-63 {
    height: 63%; }
  .circular-graph .circular-height-64 {
    height: 64%; }
  .circular-graph .circular-height-65 {
    height: 65%; }
  .circular-graph .circular-height-66 {
    height: 66%; }
  .circular-graph .circular-height-67 {
    height: 67%; }
  .circular-graph .circular-height-68 {
    height: 68%; }
  .circular-graph .circular-height-69 {
    height: 69%; }
  .circular-graph .circular-height-70 {
    height: 70%; }
  .circular-graph .circular-height-71 {
    height: 71%; }
  .circular-graph .circular-height-72 {
    height: 72%; }
  .circular-graph .circular-height-73 {
    height: 73%; }
  .circular-graph .circular-height-74 {
    height: 74%; }
  .circular-graph .circular-height-75 {
    height: 75%; }
  .circular-graph .circular-height-76 {
    height: 76%; }
  .circular-graph .circular-height-77 {
    height: 77%; }
  .circular-graph .circular-height-78 {
    height: 78%; }
  .circular-graph .circular-height-79 {
    height: 79%; }
  .circular-graph .circular-height-80 {
    height: 80%; }
  .circular-graph .circular-height-81 {
    height: 81%; }
  .circular-graph .circular-height-82 {
    height: 82%; }
  .circular-graph .circular-height-83 {
    height: 83%; }
  .circular-graph .circular-height-84 {
    height: 84%; }
  .circular-graph .circular-height-85 {
    height: 85%; }
  .circular-graph .circular-height-86 {
    height: 86%; }
  .circular-graph .circular-height-87 {
    height: 87%; }
  .circular-graph .circular-height-88 {
    height: 88%; }
  .circular-graph .circular-height-89 {
    height: 89%; }
  .circular-graph .circular-height-90 {
    height: 90%; }
  .circular-graph .circular-height-91 {
    height: 91%; }
  .circular-graph .circular-height-92 {
    height: 92%; }
  .circular-graph .circular-height-93 {
    height: 93%; }
  .circular-graph .circular-height-94 {
    height: 94%; }
  .circular-graph .circular-height-95 {
    height: 95%; }
  .circular-graph .circular-height-96 {
    height: 96%; }
  .circular-graph .circular-height-97 {
    height: 97%; }
  .circular-graph .circular-height-98 {
    height: 98%; }
  .circular-graph .circular-height-99 {
    height: 99%; }
  .circular-graph .circular-height-100 {
    height: 100%; }

.circle-graph {
  border-radius: 50%;
  margin: auto;
  background: -webkit-gradient(linear, left top, left bottom, from(#3BBDAD), to(#1E5EAC));
  background: linear-gradient(#3BBDAD, #1E5EAC);
  margin-bottom: 25px;
  -webkit-transition: all .3s;
  transition: all .3s;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  .circle-graph .circle-graph-data {
    color: #FFF;
    font-size: 20px;
    font-style: italic; }
    .circle-graph .circle-graph-data em {
      font-weight: bold;
      font-size: 32px; }
  .circle-graph.circle-little {
    width: 30px;
    height: 30px; }
    .circle-graph.circle-little .circle-graph-data {
      color: #424143;
      position: relative;
      top: -30px;
      font-size: 15px; }
      .circle-graph.circle-little .circle-graph-data em {
        font-weight: 400;
        font-size: 22px; }
  .circle-graph.circle-small {
    width: 60px;
    height: 60px; }
    .circle-graph.circle-small .circle-graph-data {
      color: #424143;
      position: relative;
      top: -45px;
      font-size: 15px; }
      .circle-graph.circle-small .circle-graph-data em {
        font-size: 23px; }
  .circle-graph.circle-medium {
    width: 90px;
    height: 90px; }
    .circle-graph.circle-medium .circle-graph-data {
      font-size: 15px; }
      .circle-graph.circle-medium .circle-graph-data em {
        font-size: 20px; }
  .circle-graph.circle-large {
    width: 120px;
    height: 120px; }
    .circle-graph.circle-large .circle-graph-data {
      font-size: 15px; }
      .circle-graph.circle-large .circle-graph-data em {
        font-size: 25px; }
  .circle-graph.circle-exlarge {
    width: 140px;
    height: 140px; }
  .circle-graph.circle-yellow {
    background: -webkit-gradient(linear, left top, left bottom, from(#FFE2A7), to(#ffc957));
    background: linear-gradient(#FFE2A7, #ffc957); }
  .circle-graph.circle-red {
    background: #C46775; }
  .circle-graph.circle-blue {
    background: #6AA4B4; }

/* ----------------------------
 *
 * Nav navbars
 *
 * --------------------------- */
.main-menu {
  z-index: 999;
  background: #1b7baa; }
  .main-menu.navbar {
    padding: 8px 0 !important;
    padding: 0.5rem 0 !important; }
  .main-menu .nav-item {
    margin-right: 30.4px;
    margin-right: 1.9rem; }
  .main-menu .nav-link {
    color: white !important;
    font-weight: bold;
    padding-right: 0 !important;
    padding-left: 0 !important; }

@media (min-width: 992px) {
  .main-menu {
    background: rgba(27, 123, 170, 0.7); }
  .navbar-nav .nav-link {
    padding-top: 10px;
    padding-bottom: 10px; } }

.nav-card {
  background: white;
  border-top: #3BBDAD 4px solid;
  font-weight: bold;
  box-shadow: 0px 2px 2px #DEDEDE;
  color: #576975;
  margin-bottom: 30px;
  border-radius: 4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  .nav-card .nav-item {
    border-right: #DDD 1px solid;
    padding: 10px 15px; }
  .nav-card .nav-title {
    font-family: "Open Sans", sans-serif;
    font-weight: bold; }
  .nav-card .nav-total {
    font-family: "Open Sans", sans-serif;
    font-weight: 700; }
  @media (max-width: 1147px) {
    .nav-card.nav-card-projects {
      font-size: 12px; } }
  @media (max-width: 933px) {
    .nav-card.nav-card-projects .nav-item {
      padding: 10px 0px; } }
  @media (max-width: 784px) {
    .nav-card .nav-item {
      padding: 5px; }
    .nav-card.nav-card-projects .nav-item {
      width: 100%;
      border-right: 0;
      border-bottom: 1px solid #ddd; }
      .nav-card.nav-card-projects .nav-item:last-child {
        border-bottom: 0; }
    .nav-card .nav-link, .nav-card .btn {
      font-size: 13px !important; } }
  @media (max-width: 600px) {
    .nav-card .nav-item {
      width: 100%;
      text-align: center;
      border-right: 0;
      border-bottom: 1px solid #CCC;
      margin: 0 !important; } }

.nav-border-top-blue {
  border-top: #0083CF 4px solid;
  border-radius: 3px; }

.department-list {
  margin-top: 20px;
  margin-bottom: 30px; }
  .department-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    vertical-align: stretch; }
    .department-list ul li {
      width: 25%;
      padding: 5px; }
      .department-list ul li a {
        background-color: #3BBDAD;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        color: #FFF;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        text-align: center;
        text-decoration: none;
        font-size: 13px;
        font-weight: bold;
        border-radius: 4px;
        padding: 10px;
        position: relative;
        height: 100%; }
      @media (max-width: 768px) {
        .department-list ul li {
          width: 33.3333%; } }
      @media (max-width: 448px) {
        .department-list ul li {
          width: 50%; } }
  .department-list.municipal-list li {
    width: 20%; }

.list-tabs {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 40px;
  border-bottom: 2px solid #d2e3f7; }
  .list-tabs .nav-item, .list-tabs .nav-link {
    height: 40px; }
  .list-tabs .nav-item {
    display: inline-block;
    font-size: 0; }
  .list-tabs .nav-link {
    display: table-cell;
    vertical-align: middle;
    padding: 0 10px;
    color: #1E5EAC;
    text-decoration: none;
    font-weight: bold;
    font-size: 15px;
    border-bottom: 2px solid transparent;
    -webkit-transition: all .1s;
    transition: all .1s; }
    .list-tabs .nav-link:hover {
      border-bottom: 2px solid #1E5EAC; }
  .list-tabs .active {
    border-bottom: 2px solid #1E5EAC; }

.nav-filter {
  padding: 5px 20px !important;
  margin-left: 0 !important; }
  .nav-filter .nav-filter__container {
    border: 1px solid #CCC;
    border-radius: 4px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  .nav-filter .nav-filter__name {
    padding: .7em 1.3em;
    border-right: 1px solid #CCC;
    border-radius: 0; }
    .nav-filter .nav-filter__name i {
      display: inline-block;
      margin-left: 10px; }
  .nav-filter .nav-filter__options {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
    .nav-filter .nav-filter__options li {
      padding: .7em 1.3em;
      position: relative; }
      .nav-filter .nav-filter__options li input[type=radio] {
        position: absolute;
        visibility: hidden; }
      .nav-filter .nav-filter__options li label {
        display: inline-block;
        position: relative;
        left: 0px;
        padding-left: 30px;
        margin-bottom: 0;
        z-index: 9; }
      .nav-filter .nav-filter__options li a {
        color: #576975;
        text-decoration: none; }
      .nav-filter .nav-filter__options li .nav-filter__radio {
        position: absolute;
        left: 19px;
        top: 13px;
        border: 1px solid #CCC;
        border-radius: 50%;
        width: 20px;
        height: 20px; }
        .nav-filter .nav-filter__options li .nav-filter__radio:before {
          display: block;
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: transparent;
          position: absolute;
          top: 3px;
          left: 3px;
          -webkit-transition: all .3s;
          transition: all .3s; }
      .nav-filter .nav-filter__options li input[type=radio]:checked ~ .nav-filter__radio:before {
        background-color: #3BBDAD; }
      .nav-filter .nav-filter__options li:last-child {
        padding-left: 0; }
        .nav-filter .nav-filter__options li:last-child .nav-filter__radio {
          left: 0; }
  @media (max-width: 1147px) {
    .nav-filter .nav-filter__radio {
      top: 8px !important; } }
  @media (max-width: 933px) {
    .nav-filter {
      padding: 7px 12px !important; }
      .nav-filter .nav-filter__name, .nav-filter .nav-filter__options li {
        padding: .7em .6em; }
      .nav-filter .nav-filter__options li .nav-filter__radio {
        left: 9px; } }
  @media (max-width: 399px) {
    .nav-filter .nav-filter__container {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
    .nav-filter .nav-filter__name {
      width: 100%;
      border-right: 0;
      border-bottom: 1px solid #ddd; }
    .nav-filter .nav-filter__options {
      width: 100%;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center; } }

/* ----------------------------
*
* Button & Link
*
* --------------------------- */
/* anchor reset */
.btn {
  padding: 8px 30px;
  font-family: "Roboto", sans-serif; }

.btn-success {
  border-color: #3BBDAD;
  background-color: #3BBDAD;
  color: #FFFFFF; }
  .btn-success:hover {
    background-color: #27a999; }

.btn.active {
  background-color: #FE9475 !important;
  font-weight: bold;
  color: #FFFFFF;
  border-bottom: 4px solid #fd4510;
  -webkit-transition: all .3s;
  transition: all .3s; }
  .btn.active:hover {
    background-color: #fd5929;
    color: #FFFFFF; }

.btn-gray {
  background-color: #DADADA;
  font-weight: bold;
  color: #595A5C;
  border-bottom: 4px solid #a7a7a7;
  -webkit-transition: all .3s;
  transition: all .3s; }
  .btn-gray:hover {
    background-color: #c1c1c1;
    color: #595A5C; }

.btn-orange {
  background-color: #FE9475;
  font-weight: bold;
  color: #FFF;
  -webkit-transition: all .3s;
  transition: all .3s; }
  .btn-orange:hover {
    background-color: #fe6d42; }

.btn-upcase {
  text-transform: uppercase; }

.btn-md {
  padding: 5px 12px;
  font-size: 14px; }

.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.button-special {
  max-width: 250px;
  padding: 15px 18px;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0; }
  .button-special span {
    display: block; }
  @media (max-width: 549px) {
    .button-special {
      position: relative; } }

.badge {
  background-color: #0275d8;
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem;
  display: inline-block;
  padding: .25em .4em;
  font-size: 100%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline; }

.btn-table {
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #FFF !important; }

.currently-viewing {
  position: absolute;
  top: 0;
  right: 0;
  color: #fe9475; }

/* ----------------------------
 *
 * tags
 *
 * --------------------------- */
.map-graph-content .col-xs-12 {
  margin-bottom: 30px; }

/* ----------------------------
 *
 * List item
 *
 * --------------------------- */
.list-pills {
  margin: 0;
  padding: 0;
  list-style: none; }
  .list-pills .list-pills-item {
    display: block; }
    .list-pills .list-pills-item span {
      padding: 8px 10px 8px 50px;
      margin-bottom: 5px;
      font-size: 14px;
      position: relative;
      display: inline-block;
      margin-right: auto;
      -webkit-transition: all .3s;
      transition: all .3s;
      cursor: pointer;
      border: 1px solid #FFF; }
      .list-pills .list-pills-item span:before {
        content: "";
        display: block;
        position: absolute;
        left: 10px;
        top: 9px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 4px solid #CCC; }
    .list-pills .list-pills-item .list-pills-desc {
      background-color: #F9F9F9;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      border: 1px solid #E2E2E2;
      padding: 10px;
      font-size: 14px;
      position: absolute;
      left: 15px;
      min-width: 300px;
      width: 100%;
      margin-top: 10px;
      z-index: 99;
      display: none;
      -webkit-transition: all .3s;
      transition: all .3s; }
      .list-pills .list-pills-item .list-pills-desc p:last-child {
        margin-bottom: 0; }
      .list-pills .list-pills-item .list-pills-desc:before {
        content: "";
        display: block;
        position: absolute;
        top: -8px;
        left: 13px;
        width: 15px;
        height: 15px;
        border-left: 1px solid #E2E2E2;
        border-top: 1px solid #E2E2E2;
        border-radius: 4px 0px 0px 0px;
        background-color: #F9F9F9;
        z-index: 100;
        -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
                transform: rotate(45deg); }
      @media (max-width: 768px) {
        .list-pills .list-pills-item .list-pills-desc {
          width: 90%; } }
    .list-pills .list-pills-item:hover span {
      background-color: #F9F9F9;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      border: 1px solid #E2E2E2; }
    .list-pills .list-pills-item:hover .list-pills-desc {
      display: block; }
    .list-pills .list-pills-item.red span:before {
      border: 4px solid #DF252D; }
    .list-pills .list-pills-item.blue span:before {
      border: 4px solid #0579C9; }
    .list-pills .list-pills-item.yellow span:before {
      border: 4px solid #F4E134; }
    .list-pills .list-pills-item.purple span:before {
      border: 4px solid #8D31AF; }
    .list-pills .list-pills-item.green span:before {
      border: 4px solid #5ED122; }
    .list-pills .list-pills-item.lightblue span:before {
      border: 4px solid #0CB4E8; }
    .list-pills .list-pills-item.orange span:before {
      border: 4px solid #FC8C1D; }
    .list-pills .list-pills-item.pink span:before {
      border: 4px solid #F46296; }
    .list-pills .list-pills-item.cream span:before {
      border: 4px solid #F2BB7E; }
    .list-pills .list-pills-item.red span:before {
      border: 4px solid #DB4251; }
    .list-pills .list-pills-item.redark span:before {
      border: 4px solid #933206; }
    .list-pills .list-pills-item.coffe span:before {
      border: 4px solid #8C7948; }
    .list-pills .list-pills-item.cyan span:before {
      border: 4px solid #0CBC9F; }

.revenue-percentage:before {
  content: "";
  display: block;
  position: absolute;
  width: 50%;
  height: 100%;
  right: 15px;
  top: 0;
  background-color: #F9F9F9; }

.revenue-percentage .revenue-percentage__width {
  display: block;
  color: #FFF;
  height: 15px;
  box-shadow: 0px 2px 2px #BFBFBF;
  border-radius: 25px 0px 0px 25px;
  min-width: 3%;
  margin-left: auto;
  margin-bottom: 5px; }

.revenue-percentage .revenue-percentage__meta {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 300;
  text-align: right;
  display: block;
  margin-bottom: 5px;
  padding-right: 15px;
  font-style: italic;
  color: #576975; }

.revenue-percentage .revenue-percentage__label {
  font-size: 13px;
  position: relative;
  margin: 0; }
  .revenue-percentage .revenue-percentage__label span {
    padding: 5px 10px 5px 40px;
    margin-bottom: 5px;
    font-size: 14px;
    position: relative;
    display: inline-block;
    margin-right: auto;
    -webkit-transition: all .3s;
    transition: all .3s;
    cursor: pointer;
    border: 1px solid #FFF; }
    .revenue-percentage .revenue-percentage__label span:before {
      content: "";
      position: absolute;
      display: block;
      left: 15px;
      top: 12px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #202020; }
  .revenue-percentage .revenue-percentage__label .revenue-percentage__childrens {
    background-color: #F9F9F9;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 1px solid #E2E2E2;
    padding: 10px;
    font-size: 14px;
    position: absolute;
    left: 0px;
    min-width: 300px;
    width: 100%;
    margin-top: 10px;
    z-index: 99;
    display: none;
    -webkit-transition: all .3s;
    transition: all .3s; }
    .revenue-percentage .revenue-percentage__label .revenue-percentage__childrens p {
      border-bottom: 1px solid #ddd;
      margin: 0;
      padding: 5px 0;
      color: #576975; }
    .revenue-percentage .revenue-percentage__label .revenue-percentage__childrens p:last-child {
      padding-bottom: 0;
      border-bottom: 0; }
    .revenue-percentage .revenue-percentage__label .revenue-percentage__childrens:before {
      content: "";
      display: block;
      position: absolute;
      top: -8px;
      left: 13px;
      width: 15px;
      height: 15px;
      border-left: 1px solid #E2E2E2;
      border-top: 1px solid #E2E2E2;
      border-radius: 4px 0px 0px 0px;
      background-color: #F9F9F9;
      z-index: 100;
      -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
              transform: rotate(45deg); }
  .revenue-percentage .revenue-percentage__label:hover span {
    background-color: #F9F9F9;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 1px solid #E2E2E2; }
  .revenue-percentage .revenue-percentage__label:hover .revenue-percentage__childrens {
    display: block; }

.revenue-percentage .revenue-line {
  border-bottom: 2px solid #F9F9F9; }

.revenue-percentage .revenue-percentage__label.blue span:before, .revenue-percentage .revenue-percentage__width.blue {
  background-color: #0579C9; }

.revenue-percentage .revenue-percentage__label.yellow span:before, .revenue-percentage .revenue-percentage__width.yellow {
  background-color: #F4E134; }

.revenue-percentage .revenue-percentage__label.purple span:before, .revenue-percentage .revenue-percentage__width.purple {
  background-color: #8D31AF; }

.revenue-percentage .revenue-percentage__label.green span:before, .revenue-percentage .revenue-percentage__width.green {
  background-color: #5ED122; }

.revenue-percentage .revenue-percentage__label.lightblue span:before, .revenue-percentage .revenue-percentage__width.lightblue {
  background-color: #0CB4E8; }

.revenue-percentage .revenue-percentage__label.orange span:before, .revenue-percentage .revenue-percentage__width.orange {
  background-color: #FC8C1D; }

.revenue-percentage .revenue-percentage__label.pink span:before, .revenue-percentage .revenue-percentage__width.pink {
  background-color: #F46296; }

.revenue-percentage .revenue-percentage__label.cream span:before, .revenue-percentage .revenue-percentage__width.cream {
  background-color: #F2BB7E; }

.revenue-percentage .revenue-percentage__label.red span:before, .revenue-percentage .revenue-percentage__width.red {
  background-color: #DB4251; }

.revenue-percentage .revenue-percentage__label.redark span:before, .revenue-percentage .revenue-percentage__width.redark {
  background-color: #933206; }

.revenue-percentage .revenue-percentage__label.coffe span:before, .revenue-percentage .revenue-percentage__width.coffe {
  background-color: #8C7948; }

.revenue-percentage .revenue-percentage__label.cyan span:before, .revenue-percentage .revenue-percentage__width.cyan {
  background-color: #0CBC9F; }

@media (max-width: 768px) {
  .revenue-percentage:before {
    display: none; }
  .revenue-percentage .revenue-line {
    border-bottom: 0; }
  .revenue-percentage .revenue-background {
    border-bottom: 2px solid #F9F9F9;
    padding-bottom: 10px; } }

.tab-graph {
  text-align: right; }
  .tab-graph .nav-item {
    float: inherit;
    display: inline-block; }

.tab-line ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 20px;
  border-bottom: 1px solid #1E5EAC; }
  .tab-line ul li {
    display: inline-block; }
    .tab-line ul li a {
      display: block;
      padding: 10px 18px;
      font-size: 15px;
      font-weight: bold;
      -webkit-transition: all .3s;
      transition: all .3s; }
      .tab-line ul li a:hover {
        text-decoration: none;
        border-bottom: 2px solid #1E5EAC; }

.tip-list {
  margin: 0;
  padding: 0;
  list-style: none; }

.list-meta {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 15px; }
  .list-meta li {
    display: inline-block;
    font-size: 14px;
    margin-right: 15px; }

/* ----------------------------
*
* Table
*
* --------------------------- */
/* Table layout */
.table {
  width: 100%;
  min-height: 0%;
  overflow-x: auto;
  border-radius: 4px; }
  .table thead th {
    vertical-align: middle; }
  .table td {
    min-width: 160px; }
  @media (max-width: 768px) {
    .table {
      display: block; } }

.graph-table {
  background: white; }
  .graph-table .graph-element {
    height: 220px; }
  .graph-table a {
    font-family: Open Sans;
    font-size: 14px;
    color: #424143;
    font-weight: 400; }
  .graph-table .vertical-graph h5 {
    font-size: 14px;
    line-height: 1.5;
    text-align: left; }
    .graph-table .vertical-graph h5 a {
      color: #424143;
      font-weight: 700; }
  .graph-table .vertical-graph .graph-percentage {
    position: absolute;
    height: 100%; }
    .graph-table .vertical-graph .graph-percentage > span {
      position: absolute;
      top: 50%;
      right: -70px;
      margin-top: -17px;
      font-size: 20px; }
      .graph-table .vertical-graph .graph-percentage > span.horizontal.graph-label--down {
        right: 20px;
        color: #FFF;
        z-index: 10; }
  .graph-table .vertical-graph .graph-amount {
    padding: 0;
    margin-bottom: 0; }
  .graph-table .vertical-graph td:first-child {
    position: relative;
    padding: 10px; }
  .graph-table .vertical-graph td:last-child {
    vertical-align: top;
    padding: 0;
    position: relative; }
  .graph-table td {
    vertical-align: bottom;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0; }
  .graph-table th {
    font-size: 14px;
    color: #424143;
    text-align: center; }
  .graph-table .graph-label {
    font-size: 20px;
    margin-bottom: 12px;
    display: block;
    font-style: italic; }
    .graph-table .graph-label em {
      font-size: 30px;
      font-weight: bold; }
    .graph-table .graph-label.vertical.graph-label--down {
      margin-bottom: -40px;
      position: relative;
      z-index: 10;
      color: #FFF;
      font-weight: 400; }
  .graph-table .graph-fill {
    background: -webkit-gradient(linear, left bottom, left top, from(#29ABE2), to(#0DDEF2));
    background: linear-gradient(to top, #29ABE2, #0DDEF2);
    position: relative; }
    .graph-table .graph-fill .graph-value {
      color: white;
      font-size: 14px;
      font-weight: bold;
      bottom: 5px;
      position: absolute;
      text-align: center;
      width: 100%; }
  .graph-table .graph-amount {
    padding: 5px;
    font-size: 14px; }
    .graph-table .graph-amount span {
      display: block; }

.table-contracts th {
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  vertical-align: middle !important; }

.table-contracts .graph-label {
  font-size: 14px; }

.table-small th,
.table-small td {
  font-size: 11px; }

#table-react, .table-react {
  border: 0 !important;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  background-color: #FFF;
  border-radius: 4px;
  width: 100% !important; }
  #table-react thead td, #table-react thead th, .table-react thead td, .table-react thead th {
    padding: 20px !important;
    text-align: center !important;
    font-weight: bold;
    text-transform: uppercase;
    color: #576975;
    border-bottom: 0 !important; }
  #table-react tbody td, .table-react tbody td {
    padding: 15px !important;
    color: #576975;
    text-transform: uppercase;
    font-size: 14px;
    text-align: center; }
  #table-react tbody td:first-child, .table-react tbody td:first-child {
    text-align: left; }
  #table-react tbody .odd, .table-react tbody .odd {
    background-color: #EEE; }

#table-react_info {
  display: none; }

.table-react-container {
  width: 100%;
  overflow-y: auto;
  margin-bottom: 40px;
  padding-top: 80px;
  padding-bottom: 15px; }

.dataTables_wrapper .row:last-child {
  display: none; }

.jqx-widget-content {
  font-family: "Roboto", sans-serif !important; }

/* ----------------------------
 *
 * Font
 *
 * --------------------------- */
/* Import External Stylesheet */
/* Font Size
-------------------------------*/
.fsize13 {
  font-size: 13px !important; }

.fsize14 {
  font-size: 14px !important; }

.fsize15 {
  font-size: 15px !important; }

.fsize16 {
  font-size: 16px !important; }

.fsize17 {
  font-size: 17px !important; }

.fsize18 {
  font-size: 18px !important; }

.fsize20 {
  font-size: 20px !important; }

.fsize24 {
  font-size: 24px !important; }

.fsize26 {
  font-size: 26px !important; }

.fsize28 {
  font-size: 28px !important; }

.fsize30 {
  font-size: 30px !important; }

.fsize32 {
  font-size: 32px !important; }

.fsize36 {
  font-size: 36px !important; }

.fsize48 {
  font-size: 48px !important; }

/* ----------------------------
 *
 * Forms
 *
 * --------------------------- */
/* ----------------------------
 *
 * Dropdown Menu
 *
 * --------------------------- */
/* ----------------------------
*
* Borders
*
* --------------------------- */
.border-bottom {
  border-bottom: #d9d9d9 1px solid; }

.popup-name {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 0 15px; }
  .popup-name .popup-description {
    background-color: #f9f9f9;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 1px solid #e2e2e2;
    padding: 10px;
    font-size: 14px;
    position: absolute;
    left: 100%;
    top: -30px;
    width: 300px;
    margin-top: 10px;
    z-index: 4;
    font-weight: 300;
    text-align: left;
    font-family: "Roboto", sans-serif;
    display: block;
    visibility: hidden;
    -webkit-transition: all .3s;
    transition: all .3s; }
    .popup-name .popup-description:before {
      content: "";
      display: block;
      position: absolute;
      top: 21px;
      left: -8px;
      width: 15px;
      height: 15px;
      border-left: 1px solid #E2E2E2;
      border-bottom: 1px solid #E2E2E2;
      border-radius: 4px 0px 0px 0px;
      background-color: #F9F9F9;
      z-index: 100;
      -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
              transform: rotate(45deg); }
  .popup-name:hover .popup-description {
    visibility: visible; }
