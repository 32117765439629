/* ----------------------------
 *
 * List item
 *
 * --------------------------- */

//justify
// .list-justify{
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     flex-flow: wrap row;

//     [class*="-item"]{
//         padding: 15px;
//         margin: 0;
//     }
// }


//this is custom  :)
.list-pills {
	margin: 0;
	padding: 0;
	list-style: none;

	.list-pills-item {
		display: block;

		span {
			padding: 8px 10px 8px 50px;
			margin-bottom: 5px;
			font-size: 14px;
			position: relative;
			display: inline-block;
			margin-right: auto;
			transition: all .3s;
			cursor: pointer;
			border: 1px solid #FFF;

			&:before {
				content: "";
				display: block;
				position: absolute;
				left: 10px;
				top: 9px;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				border: 4px solid #CCC;
			}

		}

		.list-pills-desc {
			background-color: #F9F9F9;
			box-shadow: 0px 4px 5px rgba(#000,.1);
			border-radius: 4px;
			border: 1px solid #E2E2E2;
			padding: 10px;
			font-size: 14px;
			position: absolute;
			left: 15px;
			min-width: 300px;
			width: 100%;
			margin-top: 10px;
			z-index: 99;
			display: none;
			transition: all .3s;
			p:last-child {margin-bottom:0;}

			&:before {
				content: "";
				display: block;
				position: absolute;
				top: -8px;
				left: 13px;
				width: 15px;
				height: 15px;
				border-left: 1px solid #E2E2E2;
				border-top: 1px solid #E2E2E2;
				border-radius: 4px 0px 0px 0px;
				background-color: #F9F9F9;
				z-index: 100;
				transform: rotate(45deg);
			}

			@media ( max-width: 768px ) {
				width: 90%;

			}
		}

		&:hover {

			span {
				background-color: #F9F9F9;
				box-shadow: 0px 4px 5px rgba(#000,.1);
				border-radius: 4px;
				border: 1px solid #E2E2E2;
			}

			.list-pills-desc {
				display: block;
			}
		}

		&.red span:before {
			border: 4px solid $red;
		}

		&.blue span:before { border: 4px solid $chart-blue; }
		&.yellow span:before { border: 4px solid $chart-yellow; }
		&.purple span:before { border: 4px solid $chart-purple; }
		&.green span:before { border: 4px solid $chart-green; }
		&.lightblue span:before { border: 4px solid $chart-lightblue; }
		&.orange span:before { border: 4px solid $chart-orange; }
		&.pink span:before { border: 4px solid $chart-pink; }
		&.cream span:before { border: 4px solid $chart-cream; }
		&.red span:before { border: 4px solid $chart-red; }
		&.redark span:before { border: 4px solid $chart-redark; }
		&.coffe span:before { border: 4px solid $chart-coffe; }
		&.cyan span:before { border: 4px solid $chart-cyan; }
	}

}

.revenue-percentage {

	&:before {
		content: "";
		display: block;
		position: absolute;
		width: 50%;
		height: 100%;
		right: 15px;
		top: 0;
		background-color: #F9F9F9;
	}

	.revenue-percentage__width {
		display: block;
		color: #FFF;
		//position: absolute;
		height: 15px;
		box-shadow: 0px 2px 2px #BFBFBF;
		border-radius: 25px 0px 0px 25px;
		min-width: 3%;
		margin-left: auto;
		margin-bottom: 5px;
	}

	.revenue-percentage__meta {
		font-family: $font-secondary;
		font-size: 15px;
		font-weight: 300;
		text-align: right;
		display: block;
		margin-bottom: 5px;
		padding-right: 15px;
		font-style: italic;
		color: $font-dark;
	}

	.revenue-percentage__label {
		font-size: 13px;
		position: relative;
		margin: 0;

		span {
			padding: 5px 10px 5px 40px;
			margin-bottom: 5px;
			font-size: 14px;
			position: relative;
			display: inline-block;
			margin-right: auto;
			transition: all .3s;
			cursor: pointer;
			border: 1px solid #FFF;

			&:before {
				content: "";
				position: absolute;
				display: block;
				left: 15px;
				top: 12px;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background-color: #202020;

			}
		}

		.revenue-percentage__childrens {
			background-color: #F9F9F9;
			box-shadow: 0px 4px 5px rgba(#000,.1);
			border-radius: 4px;
			border: 1px solid #E2E2E2;
			padding: 10px;
			font-size: 14px;
			position: absolute;
			left: 0px;
			min-width: 300px;
			width: 100%;
			margin-top: 10px;
			z-index: 99;
			display: none;
			transition: all .3s;

			p {
				border-bottom: 1px solid #ddd;
				margin: 0;
				padding: 5px 0;
				color: $font-dark;
			}
			p:last-child {padding-bottom:0; border-bottom: 0;}

			&:before {
				content: "";
				display: block;
				position: absolute;
				top: -8px;
				left: 13px;
				width: 15px;
				height: 15px;
				border-left: 1px solid #E2E2E2;
				border-top: 1px solid #E2E2E2;
				border-radius: 4px 0px 0px 0px;
				background-color: #F9F9F9;
				z-index: 100;
				transform: rotate(45deg);
			}
		}

		&:hover {

			span {
				background-color: #F9F9F9;
				box-shadow: 0px 4px 5px rgba(#000,.1);
				border-radius: 4px;
				border: 1px solid #E2E2E2;
			}

			.revenue-percentage__childrens {
				display: block;
			}
		}


	}

	.revenue-line { border-bottom: 2px solid #F9F9F9; }

	.revenue-percentage__label.blue span:before, .revenue-percentage__width.blue { background-color: $chart-blue; }
	.revenue-percentage__label.yellow span:before, .revenue-percentage__width.yellow { background-color: $chart-yellow; }
	.revenue-percentage__label.purple span:before, .revenue-percentage__width.purple { background-color: $chart-purple; }
	.revenue-percentage__label.green span:before, .revenue-percentage__width.green { background-color: $chart-green; }
	.revenue-percentage__label.lightblue span:before, .revenue-percentage__width.lightblue { background-color: $chart-lightblue; }
	.revenue-percentage__label.orange span:before, .revenue-percentage__width.orange { background-color: $chart-orange; }
	.revenue-percentage__label.pink span:before, .revenue-percentage__width.pink { background-color: $chart-pink; }
	.revenue-percentage__label.cream span:before, .revenue-percentage__width.cream { background-color: $chart-cream; }
	.revenue-percentage__label.red span:before, .revenue-percentage__width.red { background-color: $chart-red; }
	.revenue-percentage__label.redark span:before, .revenue-percentage__width.redark { background-color: $chart-redark; }
	.revenue-percentage__label.coffe span:before, .revenue-percentage__width.coffe { background-color: $chart-coffe; }
	.revenue-percentage__label.cyan span:before, .revenue-percentage__width.cyan { background-color: $chart-cyan; }

	@media ( max-width: 768px ) {
		&:before { display: none; }

		.revenue-line { border-bottom: 0; }
		.revenue-background { border-bottom: 2px solid #F9F9F9; padding-bottom: 10px; }
	}
}

.tab-graph {
	text-align:right;
	.nav-item {
		float: inherit;
		display: inline-block;
	}
}

.tab-line {
	ul {
		@include clean-list;
		margin-bottom: 20px;
		border-bottom: 1px solid $blue;

		li {
			display: inline-block;
			a {
				display: block;
				padding: 10px 18px;
				font-size: 15px;
				font-weight: bold;
				transition: all .3s;

				&:hover {
					text-decoration: none;
					border-bottom: 2px solid $blue;
				}
			}
		}
	}
}

.tip-list {
	@include clean-list;
	//.slick-track { width: auto !important; }
}

.list-meta {
	@include clean-list;

	margin-bottom: 15px;

	li {
		display: inline-block;
		font-size: 14px;
		margin-right: 15px;
	}
}
