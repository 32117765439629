/* ------------------------------ *
 * * * * * * * footer * * * * * * *
 * ------------------------------ */

// Footer Main
// -----------------------------------

.footer{
	// background:$aqua;
	padding:30px 0 0 0;
	font-size:13px;
	color:white;
}

.social-footer{
	a{
		color:white;
		margin-left:10px;
		font-size: 30px;
		&:hover{
			text-decoration: none;
		}
	}
}

.copyright{
	margin-top:30px;
	color: $font-light;
	background: white;
	padding: 10px 0;

	@media ( max-width: 768px ) {
		img { width: 130px; }
	}
}