/* ----------------------------
 *
 * Carousel
 *
 * --------------------------- */

#slider-header{
	top:-60px;

	.carousel-inner {
		height: 520px;

		&>.carousel-item {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;

			img {
				min-width: 100%;
			    min-height: 100%;
			    width: auto;
			    height: auto;
			    max-width: none;
			}
		}
	}

	@media ( min-width: 1000px ) {
		.carousel-caption{
			left:25%;
			right: 25%;
			padding-bottom: 10px;
		}
	}
	
	.carousel-caption h3{
		color:$aqua;
	}

	@media ( max-width: 1000px ) {
		.carousel-inner {
			height: 600px;
		}

	}

	@media ( max-width: 768px ) {
		.carousel-caption {
			font-size: 14px;

			h3 { font-size: 24px; }
		}
	}
}

.overlay{
	position:absolute;
	height:100%;
	width:100%;
	//background: rgba(0,0,0,0.3);
	background: linear-gradient( to top, rgba(60,60,60,1) 10%, rgba(60,114,206,0.5));
}