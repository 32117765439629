/* ----------------------------
 *
 * Nav breadcrumb
 *
 * --------------------------- */

.breadcrumb{
	background: white;
	font-size:14px;
	overflow-y: hidden;
	height: 42px;
	padding: 0;
	margin: 0;
	padding-right: 10px;
	
	.container, .iep-container{
		overflow-x: auto;
		white-space: nowrap; 
		padding-top: 10px;
		padding-bottom: 30px;
	}

	.breadcrumb-item {
		font-weight: bold;
		color: $font-light;
		float: inherit;
	}
}

.breadcrumb-item + .breadcrumb-item::before {
	content:"\f054";
	font: normal normal normal 14px/1 FontAwesome;
	font-size: 12px;
}