// Color variation
// ----------------------------------------------------------------------------
//-- colors:start --//
$dark:                                  #424143;
$gray:                                  #595A5C;
$light-gray:                            #F2F3F6;
$button-gray:							#DADADA;
$white:                                 #FFFFFF;

$red:                                   #DF252D;
$red-graph:								#FFE1A1;
$red-light: 							lighten($red-graph, 10%);
$orange:                                #FE9475;
$green:                                 #39b54a;
$orange-light:                          #E2DBCE;
$dark-red:                              #AD4122;
$dark-purple:                           #394450;
$blue:                                  #1E5EAC;
$aqua:									#3BBDAD;

$base-color:                            #213884;
$accent-base-color:                     #0083CF; // blue
$default:                               #EAEEF1; // #f5f5f5;
$inverse:                               #424143;

$font-dark:                             #576975;
$font-light:                            #95A1AA; //#7C8589;

$chart-blue:							#0579C9;
$chart-yellow:							#F4E134;
$chart-purple:							#8D31AF;
$chart-green:							#5ED122;
$chart-lightblue:						#0CB4E8;
$chart-orange:							#FC8C1D;
$chart-pink:							#F46296;
$chart-cream:							#F2BB7E;
$chart-red:								#DB4251;
$chart-redark:							#933206;
$chart-coffe:							#8C7948;
$chart-cyan:							#0CBC9F;


// border radius
$border-radius:                         3px;

// Typography
// ----------------------------------------------------------------------------
//-- typo:start --//
$font-family:                           'Open Sans', sans-serif;  // 300,300i,400,400i,500,500i,700,700i
$font-secondary:                        'Roboto', sans-serif;
//-- typo:end --//
$font-size-global:                      14px;

// Template layout
// ----------------------------------------------------------------------------
// Infobar
$infobar-height:                        36px;

// Header
$header-height:                         130px;
$header-height-md:                      $header-height + 10;

// Footer
$footer-height:                         110px;

// Aside
$aside-width:                           300px;

//gutter width (space between cards)
$gutter-width:                          15px;