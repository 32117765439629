/* ----------------------------*
 * Media list *
 * --------------------------- */

.media {
	display: flex;
	margin-bottom: 5px;
	margin-top: 5px;

	.media-image {
		width: 90px;
		height: auto;
		margin-right: 10px;

		img {
			width: 100%;
			height: auto;
		}
	}

	.media-body {
		width: auto;
		flex: 1 1 0%;

		p {
			font-size: 15px;
			line-height: 1.4;
		}

		a {
			color: $gray;
		}
	}

	@media ( max-width: 768px ) {
		.media-image {
			width: 100%;
			margin-right: 0;
		}
	}
}
