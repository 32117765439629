/* ----------------------------
 *
 * Helper Class
 *
 * --------------------------- */
 * {
 	box-sizing: border-box;
 }

/* Margin */
.ma35 { margin: 35px !important; }
.ma30 { margin: 30px !important; }
.ma25 { margin: 25px !important; }
.ma20 { margin: 20px !important; }
.ma15 { margin: 15px !important; }
.ma10 { margin: 10px !important; }
.ma5  { margin: 5px  !important; }
.nm   { margin: 0    !important; }
.ma-35 { margin: -35px !important; }
.ma-30 { margin: -30px !important; }
.ma-25 { margin: -25px !important; }
.ma-20 { margin: -20px !important; }
.ma-15 { margin: -15px !important; }
.ma-10 { margin: -10px !important; }
.ma-5  { margin: -5px  !important; }

.mt100 { margin-top: 100px !important }
.mt40 { margin-top: 40px !important; }
.mt35 { margin-top: 35px !important; }
.mt30 { margin-top: 30px !important; }
.mt25 { margin-top: 25px !important; }
.mt20 { margin-top: 20px !important; }
.mt15 { margin-top: 15px !important; }
.mt17 { margin-top: 17px !important; }
.mt10 { margin-top: 10px !important; }
.mt6 { margin-top: 6px !important; }
.mt5 { margin-top: 5px !important; }
.mt4 { margin-top: 4px !important; }
.mt3 { margin-top: 3px !important; }
.mt2 { margin-top: 2px !important; }
.mt1 { margin-top: 1px !important; }
.mt0 { margin-top: 0   !important; }
.mt-35 { margin-top: -35px !important; }
.mt-30 { margin-top: -30px !important; }
.mt-25 { margin-top: -25px !important; }
.mt-20 { margin-top: -20px !important; }
.mt-15 { margin-top: -15px !important; }
.mt-10 { margin-top: -10px !important; }
.mt-5  { margin-top: -5px  !important; }

.mr35 { margin-right: 35px !important; }
.mr30 { margin-right: 30px !important; }
.mr25 { margin-right: 25px !important; }
.mr20 { margin-right: 20px !important; }
.mr15 { margin-right: 15px !important; }
.mr10 { margin-right: 10px !important; }
.mr5  { margin-right: 5px  !important; }
.mr0   {  margin-right: 0    !important; }
.mr-25 { margin-right: -25px !important; }
.mr-20 { margin-right: -20px !important; }
.mr-15 { margin-right: -15px !important; }
.mr-10 { margin-right: -10px !important; }
.mr-5  { margin-right: -5px  !important; }

.mb50 { margin-bottom: 50px !important; }
.mb40 { margin-bottom: 40px !important; }
.mb35 { margin-bottom: 35px !important; }
.mb30 { margin-bottom: 30px !important; }
.mb25 { margin-bottom: 25px !important; }
.mb20 { margin-bottom: 20px !important; }
.mb15 { margin-bottom: 15px !important; }
.mb10 { margin-bottom: 10px !important; }
.mb5 { margin-bottom: 5px !important; }
.mb4 { margin-bottom: 4px !important; }
.mb3 { margin-bottom: 3px !important; }
.mb2 { margin-bottom: 2px !important; }
.mb1 { margin-bottom: 1px !important; }
.mb0 { margin-bottom: 0   !important; }
.mb-35 { margin-bottom: -35px !important; }
.mb-30 { margin-bottom: -30px !important; }
.mb-25 { margin-bottom: -25px !important; }
.mb-20 { margin-bottom: -20px !important; }
.mb-15 { margin-bottom: -15px !important; }
.mb-10 { margin-bottom: -10px !important; }
.mb-5  { margin-bottom: -5px  !important; }

.ml35 { margin-left: 35px !important; }
.ml30 { margin-left: 30px !important; }
.ml25 { margin-left: 25px !important; }
.ml20 { margin-left: 20px !important; }
.ml15 { margin-left: 15px !important; }
.ml10 { margin-left: 10px !important; }
.ml5  { margin-left: 5px  !important; }
.ml0  { margin-left: 0    !important; }
.ml-35 { margin-left: -35px !important; }
.ml-30 { margin-left: -30px !important; }
.ml-25 { margin-left: -25px !important; }
.ml-20 { margin-left: -20px !important; }
.ml-15 { margin-left: -15px !important; }
.ml-10 { margin-left: -10px !important; }
.ml-5  { margin-left: -5px  !important; }

/* Padding */
.pa35 { padding: 35px !important; }
.pa30 { padding: 30px !important; }
.pa25 { padding: 25px !important; }
.pa20 { padding: 20px !important; }
.pa15 { padding: 15px !important; }
.pa10 { padding: 10px !important; }
.pa5  { padding: 5px  !important; }
.np   { padding: 0    !important; }

.pt100 { padding-top: 100px !important }
.pt80 { padding-top: 80px !important }
.pt35 { padding-top: 35px !important; }
.pt30 { padding-top: 30px !important; }
.pt25 { padding-top: 25px !important; }
.pt20 { padding-top: 20px !important; }
.pt15 { padding-top: 15px !important; }
.pt10 { padding-top: 10px !important; }
.pt7  { padding-top: 7px  !important; }
.pt6  { padding-top: 6px  !important; }
.pt5  { padding-top: 5px  !important; }
.pt4  { padding-top: 4px  !important; }
.pt0  { padding-top: 0    !important; }

.pr35 { padding-right: 35px !important; }
.pr30 { padding-right: 30px !important; }
.pr25 { padding-right: 25px !important; }
.pr20 { padding-right: 20px !important; }
.pr15 { padding-right: 15px !important; }
.pr10 { padding-right: 10px !important; }
.pr5  { padding-right: 5px  !important; }
.pr0  { padding-right: 0    !important; }

.pb35 { padding-bottom: 35px !important; }
.pb30 { padding-bottom: 30px !important; }
.pb25 { padding-bottom: 25px !important; }
.pb20 { padding-bottom: 20px !important; }
.pb15 { padding-bottom: 15px !important; }
.pb10 { padding-bottom: 10px !important; }
.pb5  { padding-bottom: 5px  !important; }
.pb0  { padding-bottom: 0    !important; }

.pl35 { padding-left: 35px !important; }
.pl30 { padding-left: 30px !important; }
.pl25 { padding-left: 25px !important; }
.pl20 { padding-left: 20px !important; }
.pl15 { padding-left: 15px !important; }
.pl10 { padding-left: 10px !important; }
.pl5  { padding-left: 5px  !important; }
.pl0  { padding-left: 0    !important; }

/* Vertical align */
.valign-top    { vertical-align: top    !important; }
.valign-middle { vertical-align: middle !important; }
.valign-bottom { vertical-align: bottom !important; }

/* Flex Vertical align Center */
.flex-valign-center {
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
}

/* Flex Helpers */
.flex {
	display: flex;
	flex-wrap: wrap;
}

.flex-center {
	@extend .flex;
	justify-content: center;
	align-items: center;
}

.flex-align-center {
	@extend .flex;
	align-items: center;
}

.justify-center {
	justify-content: center;
}

.flex-end {
	@extend .flex;
	align-items: flex-end;
}

.flex-column {
	flex-direction: column;
}


/* Font-awesome */
.fa-mr{
	margin-right: 5px;
}
/* Misc */
.full{
	width: 100%;
}
.pull-left{
	float:left;
}
.pull-right{
	float:right;
}
.bradius0 { border-radius: 0 !important; }
.bra20 { border-radius: 20px !important; }
.bdr0, .bdr-a-0 { border-width: 0 !important; }
.bdr-t-0 { border-top-width: 0 !important; }
.bdr-b-0 { border-bottom-width: 0 !important; }
.noshadow { box-shadow: none !important;  }
.dis-none {  display: none;  }
.overflow-hidden,
.ovf-h {  overflow: hidden;  }
.float-none { float: none !important;  }
.handle { cursor: move !important;  }
.pointer { cursor: pointer !important;  }
.min-200 { min-height: 200px; }

.shadow {
	box-shadow: 0px 1px 4px rgba(#000,.1);
}

.box-shadow {
	box-shadow: 0px 2px 2px #DEDEDE;
}

.border-left { border-left: 1px solid #ddd; }
.border-right { border-right: 1px solid #ddd; }
.no-border { border: 0!important; &:after { display: none; } }
.text-center { text-align:center; }
.text-right { text-align:right; }
.bg-white { background-color: #FFF; }

.titles {
	font-size: 14px!important;
	font-weight: bold;
	color: #505050;
}

.hide, .hidden {
	display: none!important;
}


.container-large {
	.container, .iep-container {
		max-width: 1220px;
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
		margin: 0 auto;
		position: relative;

		&:after {
			clear: both;
			display: block;
			content: '';
		}
	}

}

.clearfix {
	&:before, &:after {
		content: "";
		display: block;
		clear: both;
	}
}

.loading {
	height: 200px;
	justify-content: center;
	width: 100%;
	display: flex;
	align-items: center;
	color: #505050;
}

.p-relative {
	position: relative;
}

.w-100 {
	width: 100%;
}

.divider {
	border: 1px solid #eee;
	margin-bottom: 15px;
}