/* ----------------------------
 *
 * List Group
 *
 * --------------------------- */

.list-group{
	font-size:14px;
}

.list-group-badge {
    .list-group-item {
        display: flex;
        justify-content: space-between;
    }
}

.list-group-no-border{
	.list-group-item{
		border-right:0;
		border-left:0;
		border-radius:0;
	}
	
}