/* ----------------------------
 *
 * Card
 *
 * --------------------------- */

 a:hover {
 	text-decoration: none;
 }

.card-custom{
	border:0;
	box-shadow: 0px 2px 3px #DEDEDE;
	border-radius: 4px;
	transition: all 0.3s;
	overflow: hidden;
	top:0;
	display: flex;
	flex-wrap: wrap;

	&.card-custom-budget {
		align-items: flex-end;
	}

	.card-block {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
	}

	.card-link {
		width: 100%;
		padding: 1.25rem;
		align-self: flex-end;
		text-align: center;
	}

	.card-image {
		display: block;
		position: relative;

		.card-price {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			color: #FFF;
      background: rgba($blue,0.5);
			opacity: 0;
			font-weight: bold;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: all .3s;
			i {
				display: block;
			}
		}

    &:hover .card-price{
      opacity: 1;
    }

	}

	.card-title-revenue {
		font-size: 16px !important;
		text-decoration: none;
	}

	.card-text-revenue {
		color: $font-dark;
	}

	&:hover{
		box-shadow: 0px 15px 15px #CECECE;
		transition: all 0.3s;
		top:-14px;
	}

	.card-title{
		font-size: 16px;
		line-height: 1.5;
		font-weight: bold;
		text-transform: uppercase;
		font-family: $font-family;
		color:$font-dark;
		a{
			color:$font-dark;
			text-transform: uppercase;
			font-family: $font-family;
			font-weight: bold;
		}
	}
	.card-text{
		font-size: 14px;
	}
}

.card-actividad{
  width: 33.333333%;
  background-size: cover;
  background-position: cover;
  height: 420px;
  border-right: 1px solid #FFF;
  margin: 0;
  border-bottom: 1px solid #FFF;
  border-top: 1px solid #FFF;

  @media ( max-width: 768px ) {
  	width: 100%;
  	height: auto;

  	.card-img-overlay {
  		position: relative;
  	}
  }

  &:last-child {
    border-right: 0;
  }

	.card-title{
		font-size: 18px;
		line-height: 1.4;
		font-weight: bold;
		transition: color 0.3s;
	}
	.card-text{
		color:white;
		opacity: 0.8;
		font-size: 14px;
	}

	img {
		vertical-align: bottom;
	}
	&:hover{
		.card-title{
			color:$orange;
			transition: color 0.3s;
		}
	}
}

.card-tips{
	border-radius:2px;
	background:white;
	border:0;
	color:$font-dark;
	font-weight: bold;
	font-size: 14px;
	.card-title{
		color:$orange;
		font-size: 15px;
		font-weight: bold;
	}
}

.card-top-line-orange{
	border-top: $orange 4px solid;
}
.card-top-line-aqua {
	border-top: $aqua 4px solid;
}

.card-img-overlay{
	background: linear-gradient( to top, rgba(60,114,206,0), rgba(20,20,20,0.9) 75%);
}

.card-graph {
	background: #FFF;
	border-radius: 5px;

	.card-graph__header {
		border-bottom: 1px solid #ddd;
		padding: 15px;
		position: relative;

		&:after {
			content: "";
			display: block;
			position: absolute;
			width: 15px;
			border-bottom: 1px solid #ddd;
			right: -15px;
			bottom: -1px;
		}
		span {
			font-style: italic;
			float: right;
			font-size: 25px;
			position: absolute;
			right: 0;
			top: 15px;
		}
		h4 {
			display: inline-block;
			font-weight: bold;
			font-size: 18px;
			position: relative;
			top: 7px;
			color: $dark;
			padding-right: 80px;
		}
	}

	.card-graph__desc {
		padding: 15px;
		font-size: 15px;
		font-family: $font-secondary;
		font-weight: 300;
	}

	.card-graph__body {
		padding: 15px 0;

	}

	@media ( max-width: 768px ) {
		.card-graph__header {
			&:after {
				display: none;
			}
		}
	}

	@media ( max-width: 576px ) {
		.card-graph__header {
			span {
				right: 15px;
			}
		}
	}
}

.card-list {
	box-shadow: 0px 2px 2px #DEDEDE;
	border-radius: 5px;
	margin-bottom: 15px;
	.card-content {
		width: 100%;
		padding-right: 115px;
		position: relative;

		&>.row > div { min-height: 90px; }
	}

	.card-block {
		padding: 10px 15px;
		position: relative;

		.card-description {
			background-color: #F9F9F9;
			padding: 10px;
			color: $font-dark;
			border: 1px solid #E2E2E2;
			font-size: 14px;
			box-shadow: 0px 4px 5px rgba(#000,.1);
			border-radius: 4px;
			top: 33px;
			left: 15px;
			z-index: 0;
			opacity: 0;
			position: absolute;
			transition: all .3s;

		}
		.card-title {
			margin-bottom: .30rem;
		    font-size: 16px;
		    font-weight: bold;
		    color: $font-dark;
		    cursor: pointer;
			&:hover + .card-description {
				opacity: 1;
				z-index: 100;
			}

		}
		.card-text {
			font-size: 14px;

		}
	}

	.card-button {
		background-color: $aqua;
		position: absolute;
		height: 100%;
		right: 0;
		width: 100px;
		top: 0;
		border-radius: 0px 5px 5px 0px;

		button {
			cursor: pointer;
			outline: 0;
			color: #FFF;
			background-color: transparent;
			border: 0;
			font-size: 14px;
			line-height: 1.1;
			padding: 15px;
			font-weight: bold;
			text-transform: uppercase;
			text-align: center;
			i {
				display: block;
				position: relative;
				top: 5px;
			}
		}
	}

	@media ( max-width: 768px ) {
		.card-content {
			padding-right: 0px;
		}

		.card-button {
			position: relative;
			width: 100%;
			border-radius: 0px 0px 5px 5px;
			text-align: center;
		}
		.text-center.border-right {
			border-right: 0;
			display: flex;
			align-items: stretch;

			.card-block {
				padding-right: 0;
				padding-left: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 100%;
			}
		}

		.flex-center {
			display: flex;
			align-items: stretch;

			.card-block {
				border-top: 1px solid #ddd;
				display: flex;
				align-items: center;

				&>div { width: 100%; }
			}
		}
	}

	@media ( max-width: 540px ) {
		.border-right { border-right: 0; }
		.text-center.border-right .card-block{
			border-top: 1px solid #ddd;
		}
	}
}

// default of bootstrap
.card-general {
	background-color: #FFF;
	margin-bottom: 20px;
	.card-img-top {
		width: 100%;
		height: auto;
	}

	.card-title {
		font-size: 1.1rem;
		font-weight: bold;
		color: $font-dark;
	}
}

.card-publications {
	border: 1px solid #ddd;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 0px 2px 2px #DEDEDE;
	background-color: #FFF;
	margin-bottom: 15px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;

	&>a {
		display: block;
		width: 100%;
	}

	.card-cover {
		background-size: cover;
	    background-position: center;
	    height: 200px;
	    width: 100%;
	}

	.card-block {
		padding: 15px;
		width: 100%;

		p {
			margin-bottom: 0;
			font-size: 13px;
			font-style: italic;
		}
	}
	.card-title {
		font-family: $font-secondary;
		font-weight: 400;
		font-size: 17px;
		color: $font-dark;
		margin-bottom: 5px;
	}

	.card-options {
		width: 100%;
		margin: 0;
		padding: 10px 15px;
		background-color: #F9F9F9;
		border-top: 1px solid #ddd;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		align-self: flex-end;

		a {
			display: block;
			color: $font-dark;
			cursor: pointer;
			text-align: center;
			i {
				display: block;
			}
			span {
				font-size: 12px;
				text-transform: uppercase;
			}
		}
	}

}

.card-button {
	cursor: pointer;
	padding: 5px 10px;
	border-radius: 25px;
	background-color: #EEE;
	border: 1px solid darken(#EEE, 5%);
	color: #202020;

	&.orange {
		background-color: $orange;
		border: darken($orange, 5%);
		color: #FFF;
	}
}

.card-presentations {
	border: 1px solid #EEEEEE;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 0px 2px 2px #DEDEDE;
	background-color: #FFF;
	margin-bottom: 15px;

	.card-hero {
		position: relative;
    background-size: cover;
    background-position: center;
    height: 200px;

		.button-download {
			cursor: pointer;
			position: absolute;
			top: 15px;
			right: 15px;
			background-color: $orange;
			border: 1px solid darken($orange, 5%);
			padding: 5px 10px;
			color: #FFF;
			border-radius: 25px;
			font-size: 20px;
			box-shadow: 0px 3px 3px rgba(#000,.2);
			transition: all .3s;

			&:hover {
				background-color: darken($orange, 8%);
				box-shadow: 0px 6px 6px rgba(#000, .2);
			}
		}
	}

	.card-title{
		margin-bottom: 0;
		a {
			color: #202020;
			font-weight: 400;
			margin-bottom: 0;

		}
	}

	.card-text {
		font-style: italic;
	}

	.card-button {
		display: block;
		color: #FFF;
		text-align: center;
		background-color: $blue;
		border: 1px solid darken($blue, 5%);
		text-transform: uppercase;
		font-weight: 400;
		font-size: 15px;
		padding: 10px;
		box-shadow: 0px 3px 3px #DEDEDE;
		transition: all .3s;

		&:hover {
			background-color: darken($blue, 6%);
			box-shadow: 0px 10px 10px #DEDEDE;
		}
	}
}

.card-video {
	background-color: #FFF;
	border: 1px solid #EEE;
	box-shadow: 0px 2px 2px #DEDEDE;
	margin-bottom: 15px;
	border-radius: 4px;
	overflow: hidden;

	.card-image {
		background-size: 120%;
		height: 150px;
		background-position: center center;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;

		&:before {
			content: "";
			display: block;
			position: absolute;
      top: 0;
      left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			background: rgba(#000, .2);
		}

		i {
			color: #FFF;
			font-size: 60px;
			position: relative;
			z-index: 10;
		}
	}

	.card-content {
		padding: 10px;
		h4 {
			font-size: 15px;
			font-weight: 300;
			font-family: Roboto;
			color: $font-dark;
			margin: 0;
		}
	}
}
