/* ----------------------------
 *
 * Font
 *
 * --------------------------- */

/* Import External Stylesheet */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i,800,800i');

/* Font Size
-------------------------------*/
.fsize13 { font-size: 13px !important; }
.fsize14 { font-size: 14px !important; }
.fsize15 { font-size: 15px !important; }
.fsize16 { font-size: 16px !important; }
.fsize17 { font-size: 17px !important; }
.fsize18 { font-size: 18px !important; }
.fsize20 { font-size: 20px !important; }
.fsize24 { font-size: 24px !important; }
.fsize26 { font-size: 26px !important; }
.fsize28 { font-size: 28px !important; }
.fsize30 { font-size: 30px !important; }
.fsize32 { font-size: 32px !important; }
.fsize36 { font-size: 36px !important; }
.fsize48 { font-size: 48px !important; }