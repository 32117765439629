/* ---------------------------- *
 * Template Section *
 * --------------------------- */

.section-title {
	font-size: 20px;
	font-weight:bold;
	color:$font-dark;
	text-transform: uppercase;
}

.page-title {
	@extend .section-title;
	font-size: 20px;
}

.page-header{
	background-repeat:no-repeat;
	background-position: right;
	min-height: 200px;
	padding-top: 40px;
	padding-right: 50%;
	padding-left: 15px;
	position: relative;
	border-radius: 5px;
	overflow: hidden;
	margin-bottom: 15px;
	border-radius: 4px;
	border: 1px solid #eee;

	h2,p{
		position: relative;
	}
	.page-header-overlay{
		top:0;
		left:0;
		position:absolute;
		height:100%;
		width:100%;
		//background: rgba(0,0,0,0.3);
		background: linear-gradient( to right, rgba(41,145,246,1), rgba(13,222,242,0.7));
	}

	@media ( max-width: 768px ) {
		height: auto;
		background-position: center;
		background-size: cover;
		padding-right: 15px;
		color: $font-dark;
	}
}

.image-gastos {
	background-image: url(../images/header-projects.png);
}

.page404 {
	margin-bottom: 100px;
	i {
		font-size: 50px;
		margin-bottom: 30px;
		color: $red;
	}
	h1 {
		color: $font-dark;
		font-size: 50px;
		font-weight: bold;
	}

	p {
		color: $font-dark;
		font-size: 20px;
	}
}