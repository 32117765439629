/* ----------------------------
*
* Table
*
* --------------------------- */

/* Table layout */
// .table-layout {
//     display: table;
//     width: 100%;
//     table-layout: fixed;
//     padding: 0;

//     .table-layout-section {
//         display: table-cell;
//         table-layout: fixed;
//         float: none;
//         vertical-align: top;
//     }
// }

.table {
    width: 100%;
    min-height: 0%;
    overflow-x: auto;
    border-radius: 4px;
	//re-define vertical align
	thead th {
		vertical-align: middle;
	}

	td {
		min-width: 160px;
	}

	@media ( max-width: 768px ) {
		display: block;
	}
}

.graph-table{
	background: white;
	.graph-element{
		height: 220px;
	}

	a {
		font-family: Open Sans;
		font-size: 14px;
		color: $dark;
		font-weight: 400;
	}
	// styles for table vertical
	.vertical-graph {
		h5 {
			font-size: 14px;
			line-height: 1.5;
			text-align: left;
			a {
				color: $dark;
				font-weight: 700;
			}
		}

		.graph-percentage {
			position: absolute;
			height: 100%;

			&>span {
				position: absolute;
				top: 50%;
				right: -70px;
				margin-top: -17px;
				font-size: 20px;

				&.horizontal.graph-label--down {
					right: 20px;
					color: #FFF;
					z-index: 10;
				}
			}
		}

		.graph-amount {
			padding: 0;
			margin-bottom: 0;


		}

		td:first-child {
			position: relative;
			padding: 10px;
		}

		td:last-child {
			vertical-align: top;
			padding: 0;
			position: relative;
		}
	}
	td{
		vertical-align: bottom;
		padding-left:0;
		padding-right:0;
		padding-bottom:0;
	}

	th {
		font-size: 14px;
		color: $dark;
		text-align: center;
	}
	.graph-label{
		font-size: 20px;
		margin-bottom: 12px;
		display: block;
		font-style: italic;
		em {
			font-size: 30px;
			font-weight: bold;
		}

		&.vertical.graph-label--down {
			margin-bottom: -40px;
			position: relative;
			z-index: 10;
			color: #FFF;
			font-weight: 400;
		}
	}
	.graph-fill{
		background: linear-gradient( to top, #29ABE2, #0DDEF2);
		position:relative;

		.graph-value{
			color:white;
			font-size: 14px;
			font-weight: bold;
			bottom:5px;
			position: absolute;
			text-align:center;
			width: 100%;
		}
	}

	.graph-amount {
		padding: 5px;
		font-size: 14px;

		span {
				display: block;
			}
	}
}

.table-contracts {
	th {
		text-align: center;
		text-transform: uppercase;
		font-size: 12px;
		vertical-align: middle !important;
	}

	.graph-label {
		font-size: 14px;
	}
}

.table-small {

	th,
	td {
		font-size: 11px;
	}
}

#table-react, .table-react {
	border: 0 !important;
	box-shadow: 0px 2px 3px rgba(#000, .1);
	background-color: #FFF;
	border-radius: 4px;
	width: 100% !important;

	thead {
		td, th {
			padding: 20px !important;
		    text-align: center !important;
		    font-weight: bold;
		    text-transform: uppercase;
		    color: $font-dark;
		    border-bottom: 0 !important;
		}
	}

	tbody {
		td {
			padding: 15px !important;
			color: $font-dark;
			text-transform: uppercase;
			font-size: 14px;
			text-align: center;
		}

		td:first-child {
			text-align: left;
		}

		.odd {
			background-color: #EEE;
		}
	}
}

#table-react_info { display: none; }

.table-react-container {
	width: 100%;
	overflow-y: auto;
	margin-bottom: 40px;
	padding-top: 80px;
	padding-bottom: 15px;
}

.dataTables_wrapper {
	.row:last-child { display: none; }
}

// Estilos correspondientes a la fucking tabla de jqxGrid
.jqx-widget-content {
  font-family: $font-secondary !important;
}
