/* ----------------------------
 *
 * Tooltip
 *
 * --------------------------- */

.tooltip-inner{
	background: #576975;
	font-size:11px;
	&:before{
		border-bottom-color:#576975 !important;
	}
}
