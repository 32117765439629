/* ----------------------------
 * * Template Header *
 * --------------------------- */

// Header Main
// -----------------------------------
header{
    background: white;
    
    .img-row{
        display: flex;
        align-items: center;
    }
}
.banner{
	padding:15px 0;
	background: white;
}

@media ( max-width: 768px ) {
	.header-primary {
		img {
			width: 140px;
        }
	}
}