/* ----------------------------
 *
 * tags
 *
 * --------------------------- */

 .map-graph-content {
 	
 	.col-xs-12 {
 		margin-bottom: 30px;
 	}
 }