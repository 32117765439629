.popup-name {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 0 15px;

  .popup-description {
    background-color: #f9f9f9;
    box-shadow: 0 4px 5px rgba(#000,.1);
    border-radius: 4px;
    border: 1px solid #e2e2e2;
    padding: 10px;
    font-size: 14px;
    position: absolute;
    left: 100%;
    top: -30px;
    width: 300px;
    margin-top: 10px;
    z-index: 4;
    font-weight: 300;
    text-align: left;
    font-family: $font-secondary;
    display: block;
    visibility: hidden;
    transition: all .3s;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 21px;
      left: -8px;
      width: 15px;
      height: 15px;
      border-left: 1px solid #E2E2E2;
      border-bottom: 1px solid #E2E2E2;
      border-radius: 4px 0px 0px 0px;
      background-color: #F9F9F9;
      z-index: 100;
      transform: rotate(45deg);
    }
  }

  &:hover {
    .popup-description {
      visibility: visible;
    }
  }
}
