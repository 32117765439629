//clear float mixin (mixins)
@mixin clearfix {
	&:before, &:after {
		content: "";
		display: table;
		clear: both;
	}
}

//quit defaults setting of the ul tags
@mixin clean-list {
	margin: 0;
	padding: 0;
	list-style: none;
}