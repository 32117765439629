/* ----------------------------
 *
 * Nav navbars
 *
 * --------------------------- */

.main-menu{
	z-index: 999;
	background:rgb(27,123,170);
	&.navbar {
		padding: .5rem 0!important;
	}

	.nav-item{
		margin-right: 1.9rem;
	}

	.nav-link{
		color: white !important;
		font-weight:bold;
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
}

@media ( min-width: 992px ) {
	.main-menu {
		background:rgba(27,123,170,0.7);
	}
	.navbar-nav .nav-link {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}




.nav-card{
	background: white;
	border-top: $aqua 4px solid;
	font-weight: bold;
	box-shadow: 0px 2px 2px #DEDEDE;
	color: $font-dark;
	margin-bottom: 30px;
	border-radius: 4px;
	display: flex;
	flex-wrap: wrap;

	.nav-item{
		border-right: #DDD 1px solid;
		padding: 10px 15px;
	}

	.nav-title {
		font-family: $font-family;
		font-weight: bold;
	}

	.nav-total {
		font-family: $font-family;
		font-weight: 700;
	}

	@media ( max-width: 1147px ) {
		&.nav-card-projects {
			font-size: 12px;
		}
	}

	@media ( max-width: 933px ) {
		&.nav-card-projects {
			.nav-item {
				padding: 10px 0px;
			}
		}
	}

	@media ( max-width:784px ) {
		.nav-item {
			padding: 5px;
		}

		&.nav-card-projects .nav-item {
			width: 100%;
			border-right: 0;
			border-bottom: 1px solid #ddd;

			&:last-child {
				border-bottom: 0;
			}
		}

		.nav-link, .btn {
			font-size: 13px!important;
		}
	}

	@media ( max-width: 600px ) {
		.nav-item {
			width: 100%;
			text-align: center;
			border-right: 0;
			border-bottom: 1px solid #CCC;
			margin: 0!important;
		}
	}
}

// .nav-card-container {
// 	overflow-y: hidden;
// 	height: 65px;
// 	margin-bottom: 20px;
// 	position: relative;
// 	border-radius: 4px;

// 	&:before {
// 		content: "";
// 		display: block;
// 		position: absolute;
// 		right: 0;
// 		top: 0;
// 		width: 60px;
// 		height: 100%;
// 		background: linear-gradient(to right, rgba(#000,0.0), rgba(#000, 0.1));
// 	}

// 	.nav-card {
// 		overflow-x: auto;
// 		white-space: nowrap;
// 		padding-bottom: 30px;

// 		.nav-item {
// 			float: inherit;
// 			display: inline-block;
// 		}
// 	}
// }

.nav-border-top-blue{
	border-top: $accent-base-color 4px solid;
	border-radius: 3px;
}

.department-list {
	margin-top: 20px;
	margin-bottom: 30px;
	ul {
		@include clean-list;
		display: flex;
		flex-wrap: wrap;
		margin-left: -5px;
		margin-right: -5px;
		vertical-align: stretch;

		li {
			width: 25%;
			padding: 5px;
			
			a {
				background-color: $aqua;
				display: flex;
				color:#FFF;
				justify-content: center;
				align-items: center;
				text-align: center;
				text-decoration: none;
				font-size: 13px;
				font-weight: bold;
				border-radius: 4px;
				padding: 10px;
				position: relative;
				height: 100%;
			}

			@media( max-width: 768px ) {
				width: 33.3333%;
			}

			@media ( max-width: 448px ) {
				width: 50%;
			}
		}
	}

	&.municipal-list li { width: 20%; }
}

.list-tabs {
	@include clean-list;
	height: 40px;
	border-bottom: 2px solid lighten($blue, 50%);


	.nav-item, .nav-link { height: 40px; }

	.nav-item { 
		display: inline-block; 
		font-size: 0;
	}

	.nav-link {
		display: table-cell;
		vertical-align: middle;
		padding: 0 10px;
		color: $blue;
		text-decoration: none;
		font-weight: bold;
		font-size: 15px;
		border-bottom: 2px solid transparent;
		transition: all .1s;

		&:hover {
			border-bottom: 2px solid $blue;
		}
	}
	
	.active {
		border-bottom: 2px solid $blue;
	}
}

.nav-filter {
	padding: 5px 20px !important;
	margin-left: 0!important;
	
	.nav-filter__container {
		border: 1px solid #CCC;
		border-radius: 4px;
		display: flex;

	}

	.nav-filter__name {
		padding: .7em 1.3em;
		border-right: 1px solid #CCC;
		border-radius: 0;

		i {
			display: inline-block;
			margin-left:10px;
		}
	}

	.nav-filter__options {
		@include clean-list;
		display: flex;
		align-items: center;

		li {
			padding: .7em 1.3em;
			position: relative;

			input[type=radio] {
				position: absolute;
				visibility: hidden;
			}

			label {
				display: inline-block;
				position: relative;
				left: 0px;
				padding-left: 30px;
				margin-bottom: 0;
				z-index: 9;
			}

			a {
				color: $font-dark;
				text-decoration: none;
			}

			.nav-filter__radio {
				position: absolute;
				left: 19px;
				top: 13px;
				border: 1px solid #CCC;
				border-radius: 50%;
				width: 20px;
				height: 20px;

				&:before {
					display: block;
					content: '';
					width: 12px;
					height: 12px;
					border-radius: 50%;
					background-color: transparent;
					position: absolute;
					top: 3px;
					left: 3px;
					transition: all .3s;
				}
			}

			input[type=radio]:checked ~ .nav-filter__radio:before {
				background-color: $aqua;
			}


			&:last-child {
				padding-left: 0;

				.nav-filter__radio {
					left: 0;
				}
			}
		}
	}

	@media ( max-width: 1147px ) {
		.nav-filter__radio {
			top: 8px !important;
		}
	}

	@media ( max-width: 933px ) {
		padding: 7px 12px !important;

		.nav-filter__name, .nav-filter__options li {
			padding: .7em .6em;
		}
		.nav-filter__options li .nav-filter__radio {
			left: 9px;
		}
	}

	@media ( max-width: 399px ) {
		.nav-filter__container {
			flex-wrap: wrap;
		}

		.nav-filter__name {
			width: 100%;
			border-right: 0;
			border-bottom: 1px solid #ddd;
		}
		.nav-filter__options {
			width: 100%;
			justify-content: center;
		}
	}
}
