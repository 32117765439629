/* ----------------------------
 *
 * Progress
 *
 * --------------------------- */


.progress[value]::-ms-fill {
  background: linear-gradient( to right, rgba(80,223,165,1), rgba(61,156,169,1));
}
.progress[value]::-moz-progress-bar {
  background: linear-gradient( to right, rgba(80,223,165,1), rgba(61,156,169,1));
}
.progress[value]::-webkit-progress-value {
  background: linear-gradient( to right, rgba(80,223,165,1), rgba(61,156,169,1));
}

.progress-content {
	position: relative;
	background-color: $light-gray;
	border-radius: 50px;
	margin-bottom: 35px;
	width: 100%;

	.progress-percentage {
		width: 100%;
		height: 10px;
		background: linear-gradient( to right, rgba(80,223,165,1), rgba(61,156,169,1));
		position: relative;
		border-radius: 50px;

		.progress-tooltip {
			position: relative;
			top: 20px;
			background-color: $gray;
			padding: 5px 0px;
			color: #FFF;
			font-size: 10px;
			border-radius: 5px;
			position: absolute;
			right: -13px;
			width: 40px;
			text-align: center;
			&:after {
				content: "";
				display: block;
				width: 10px;
				height: 10px;
				position: absolute;
				top: -4px;
				right: 15px;
				transform: rotate(45deg);
				background-color: $gray;
			}

			&:before {
				content: "";
				display: block;
				width: 5px;
				height: 5px;
				border-radius: 50px;
				position: absolute;
				top: -18px;
				right: 17px;
				background-color: $gray;
			}
		}
	}

	&.progress-fat {
		.progress-percentage {
			height: 15px;

			.progress-tooltip {
				top: 23px;
			}
		}
	}
	
	.progress-0 {
		width: 1%;
	}

	@for $i from 1 through 100 {
		.progress-#{$i} {
			width: ($i + %);
		}
	}	

}
/*
* Circle progress
*/
.circle-progress {
	width: 100%;
	height: 250px;
	position: relative;
	padding: 15px;
	text-align: center;

	.circle-item {
		max-width: 100%;
		width: 200px;
		height: 200px;
		border-radius: 50%;
		border: 5px solid $red;
	}

}

.circle-content {
	margin-bottom: 55px;
	border-bottom: 1px solid #CCC;
}

// circular graph
.circular-graph {
	padding-bottom: 100%;
	width: 100%;
	height: 0;
	border-radius: 50%;
	border: 1px solid #ddd;
	background-color: #f5f8fb;
	box-shadow: 0px 1px 10px rgba(#000,.1);
	overflow: hidden;
	margin: 15px auto;
	position: relative;


	.circular-graph-data {
		position: absolute;
	    text-align: center;
	    top: 0;
	    width: 100%;
	    z-index: 10;
	    margin-top: -25px;
	    font-weight: bold;
	    color: #505050;
	    font-style: italic;
	    left: 0;

	}
    
    .down-index > .circular-graph-data {
    	margin-top: 15px;
    	color: #FFF;
    }

	.circular-graph-percentage {		
		position: absolute;
		width: 100%;
		height: 1%;
		background: linear-gradient($aqua,$blue);
		bottom: 0;
		transition: all .6s;

		&:before {
			content: '';
			display: block;
			width: 100%;
			background-image: url(../images/circle-graph-bg.png);
			background-size: cover;
			background-position: bottom;
			height: 15px;
			position: relative;
			top: 0;
			left: 0;
		}
	}

	&.yellow-graph .circular-graph-percentage {
		
		background: linear-gradient($red-light, $red-graph);
	}

	&.red-graph .circular-graph-percentage {
		background: #C46775;
	}

	&.blue-graph .circular-graph-percentage {
		background: #6AA4B4;
	}

	@for $i from 1 through 100 {
		.circular-height-#{$i} {
			height: ($i + %);
		}
	}
}

.circle-graph {
	border-radius: 50%;
	margin: auto;
	background: linear-gradient($aqua,$blue);
	margin-bottom: 25px;
	transition: all .3s;
	display: flex;
	justify-content: center;
	align-items: center;

	.circle-graph-data {
		color: #FFF;
		font-size: 20px;
		font-style: italic;
		em {
			font-weight: bold;
			font-size: 32px;
		}
	}
	
	&.circle-little {
		width: 30px;
		height: 30px;
		.circle-graph-data {
			color: $dark;
			position: relative;
			top: -30px;
			font-size: 15px;
			em {
				font-weight: 400;
				font-size: 22px;
			}
		}
	}

	&.circle-small {
		width: 60px;
		height: 60px;

		.circle-graph-data {
			color: $dark;
			position: relative;
			top: -45px;
			font-size: 15px;
			em {
				font-size: 23px;
			}
		}
	}

	&.circle-medium {
		width: 90px;
		height: 90px;

		.circle-graph-data {
			font-size: 15px;
			em {
				font-size: 20px;
			}
		}
	}

	&.circle-large {
		width: 120px;
		height: 120px;
		.circle-graph-data {
			font-size: 15px;
			em {
				font-size: 25px;
			}
		}
	}

	&.circle-exlarge {
		width: 140px;
		height: 140px;

	}

	&.circle-yellow {
		background: linear-gradient(#FFE2A7, #ffc957);
	}

	&.circle-red {
		background: #C46775;
	}

	&.circle-blue {
		background: #6AA4B4;
	}

	

}